@import '../../_styles/breakpoints';

.shopping-cart-item {
  display: grid;
  grid-template-areas: 'image info quantity' 'image description quantity';

  &--b2b {
    grid-template-areas: 'image info quantity';
  }

  grid-template-columns: min-content auto min-content;
  gap: 0.8rem 1.6rem;
  padding: 1.2rem 1.6rem;

  img,
  .empty-img {
    grid-area: image;
  }

  .empty-img {
    width: 8rem;
    height: 8rem;
  }

  &__content {
    grid-area: info;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;

    h2 {
      font-weight: 800;
      font-size: 1.8rem;
      line-height: 1;
    }

    .price {
      font-size: 1.6rem;
      line-height: 1.5;
      font-feature-settings: 'tnum' on, 'lnum' on;
      margin-top: 1rem;
    }
  }

  .delivery-info {
    grid-area: description;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.7;
  }

  &__actions {
    grid-area: quantity;
    display: flex;
    align-items: center;

    > div {
      display: flex;
    }

    .button--icon,
    .shopping-cart-item__remove {
      padding: 0.6rem 1.2rem;
    }

    .shopping-cart-item__remove {
      margin-left: 1.6rem;
    }

    .quantity-input__btn.subtract-btn {
      background-color: var(--secondary);
    }

    .quantity-input__btn.add-btn {
      background-color: var(--secondary);
    }

    .quantity-input .quantity-input__quantity input {
      color: unset;
    }
  }

  @include breakpoint-down(mobile) {
    padding: 1.2rem 0;
    grid-template-areas: 'image info' 'image quantity' 'description description';
  }
}
