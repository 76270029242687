@import '../../../_styles//mixins';

.socials {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  .button:not(:last-child) {
    margin-right: 1.5rem;
  }
}

.global-nav {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: var(--background);
  padding: 2rem;

  @supports (backdrop-filter: blur(100px)) {
    background-color: var(--background-transparent);
    backdrop-filter: blur(100px);
  }

  .store-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .icon {
      margin-right: 0.8rem;
    }
  }

  > button {
    padding: 1.6rem 0.6rem;
    color: var(--primary-text);
  }

  .controls-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .logo-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-right: 1rem;
      .logo {
        margin-top: 0.2rem;
      }
    }
  }

  ul {
    position: relative;
    padding: 1.2rem 0;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      @include divider();
    }
  }

  li {
    text-align: center;
    line-height: 2.4rem;
    padding: 1.2rem;
  }

  a {
    text-decoration: none;
  }

  .language-picker {
    display: flex;
    justify-content: center;

    li {
      padding: 1.2rem 0;
    }
  }

  .selected-company {
    display: flex;
    flex-direction: column;
    p {
      font-size: large;
    }
    small {
      font-weight: bold;
      opacity: 85%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      div {
        margin-right: 0.5rem;
      }
    }
  }
}
