.tooltip {
  position: relative;
  padding: 2.4rem;
  background: var(--warning);
  border-radius: 8px;

  .tippy-content {
    padding: 0;
  }
}

.icon {
  vertical-align: middle;
}

.tooltip__trigger,
.tooltip__close {
  color: inherit;
  cursor: pointer;
  appearance: none;
  border: 0;
  background: transparent;
  padding: 0;
}

.tooltip__trigger {
  opacity: 0.5;
}

.tooltip__close {
  position: absolute;
  top: 0;
  right: 0;
}
