@import '../../_styles/breakpoints';

.product--detail {
  margin-top: 1rem;
  @include breakpoint-down(mobile) {
    > * + * {
      margin-top: 2.4rem;
    }
  }

  @include breakpoint-up(mobile) {
    .product-wrapper {
      display: grid;
      gap: 2.4rem;
      grid-template-columns: 1fr 280px;
      margin-bottom: 1rem;

      &__header,
      .product--detail__header {
        max-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        figure {
          max-height: 100%;
          img {
            max-height: 20rem;
            width: auto;
            object-fit: cover;
          }
        }
      }
    }
    .product__information {
      margin-top: 1.5rem;
    }

    .bulk-pricing {
      margin-top: 1.5rem;
    }

    .product__contents {
      margin-top: 2.5rem;
    }
  }
}

.my-kinepolis {
  margin-top: 5rem;
}

.product__information {
  margin-top: 1.5rem;
}

.bulk-pricing {
  margin-top: 1.5rem;
}

.product__contents {
  margin-top: 2.5rem;
}

.empty-content {
  display: grid;
  place-items: center;
  min-height: 200px;
}
