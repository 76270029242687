@import '../../_styles/breakpoints';
@import '../../_styles/mixins';

.shopping-cart-overview {
  h1 {
    text-align: center;
  }

  &__content {
    > *:not(:nth-last-child(-n + 3)) {
      position: relative;

      &:after {
        content: '';
        height: 1px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        @include divider();
      }
    }
  }

  &__empty {
    text-align: center;
    margin: 2.4rem;
  }

  .spinner-wrapper {
    margin: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
  }

  .discount-form {
    margin-top: 2.4rem;
  }

  &__actions {
    margin-top: 2.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    @include breakpoint-down(mobile) {
      flex-direction: column;
      .button {
        justify-content: center;
        text-align: center;
        width: 100%;
      }

      > * + * {
        margin-top: 1.6rem;
      }
    }
  }

  &__unsatisfied-coupon {
    padding: 1.6rem;
    background-color: var(--secondary);
    margin-bottom: 3.6rem;
    border-radius: 8px;
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-gap: 1.6rem;

    > .icon {
      margin-top: 0.8rem;
    }

    li {
      display: flex;
      align-items: center;

      & + li {
        margin-top: 1.6rem;
      }

      > * + * {
        margin-left: 0.8rem;
      }
    }

    button {
      margin-left: auto;
    }
  }

  .quantity-input__quantity {
    input {
      color: var(--grey-700);
    }
  }

  .shopping-cart-item {
    &__remove {
      .spinner-wrapper {
        margin: initial;
        min-height: auto;
      }
    }
  }
}
