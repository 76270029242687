@import '../../../_styles/colors';

.order-list-item {
  cursor: pointer;

  .icon {
    margin-right: 0.6rem;
    opacity: 90%;
    stroke: var(--primary-text);
    fill: var(--primary-text);
  }
}
