.btn-dropdown {
  position: relative;

  [data-tippy-root] {
    min-width: 100%;
  }

  li + li {
    margin-top: 4px;
  }

  .tippy-box {
    border-radius: 8px;
  }

  .tippy-content {
    padding: 0.4rem;
    border-radius: 8px;
    overflow: hidden;

    a,
    button {
      text-decoration: none;
      display: block;
      width: 100%;
      padding: 8px;
      text-align: left;
      border-radius: 8px;
      line-height: 1.5;
      white-space: nowrap;

      &:hover {
        background-color: var(--grey-100);
      }
    }
  }
}
