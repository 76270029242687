.register-company {
  .page-title {
    text-align: center;
  }
}

.register-company__form {
  margin-top: 1.5rem;
  .google-places-container {
    margin-top: 0;
    margin-bottom: 2rem;
  }
}

.register-company__form__actions {
  margin-top: 4rem;
  margin-bottom: 2.4rem;
  grid-column: 1 / -1;
  text-align: center;

  button {
    padding: 1.2rem;
    min-width: 288px;
    min-height: 48px;
    justify-content: center;
  }
}

.register-company__heading {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 600;
  margin-bottom: 1em;
}

.register-company__result {
  margin-top: 12rem;
  text-align: center;

  p + p {
    margin-top: 1.6rem;
  }
}

.register-company__result__button {
  margin-top: 3.2rem;
  min-width: 288px;
  min-height: 48px;
  justify-content: center;
}

.input--phone {
  width: 100%;
}
