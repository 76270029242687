.toast-container {
  .toast {
    border-radius: 0.4rem;
    font-size: 1.6rem;
    font-family: proxima-nova, sans-serif;
    padding: 1.6rem 1rem 1.6rem 1.6rem;
    cursor: default;
    display: flex;
    align-items: center;

    background-color: var(--primary-text);
    color: var(--secondary);

    .toast-body {
      padding-right: 1rem;
    }

    &.Toastify__toast {
      &--error {
        color: var(--primary-text);
        background-color: var(--error);

        .toast-body {
          padding-left: 2.8rem;
        }

        &:after {
          content: '';
          color: var(--primary-text);
          background-image: url('./_assets/images/alert_filled.svg');
          background-size: contain;
          height: 2rem;
          width: 2rem;
          position: absolute;
          left: 1.6rem;
          top: 2.2rem;
        }
      }

      &--success {
        color: var(--primary-text);
        background-color: var(--success);

        .toast-body {
          padding-left: 2.8rem;
        }

        &:after {
          content: '';
          background-image: url('./_assets/images/check_filled.svg');
          background-size: contain;
          height: 2rem;
          width: 2rem;
          position: absolute;
          left: 1.6rem;
          top: 2.2rem;
        }
      }

      &--warning {
        color: var(--primary-text);
        background-color: var(--warning);
      }
    }

    .Toastify__close-button--info {
      color: var(--secondary);
    }
  }

  .toast-progress {
    background: var(--shadow);
  }
}
