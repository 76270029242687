$padding-vertical: 1.2rem;
$padding-horizontal: 2.4rem;
$icon-height: 2rem;

@mixin button {
  text-decoration: none;
  border-radius: 8px;
  padding: $padding-vertical $padding-horizontal;
  border: none;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;

  &.button--icon {
    padding: $padding-vertical;
  }

  &[disabled],
  &[aria-disabled='true'] {
    opacity: var(--disabled-opacity);
    cursor: not-allowed;
  }
}

@mixin link {
  background-color: transparent;
  -webkit-appearance: none;
  border: 0;
  padding: 0;

  &[disabled],
  &[aria-disabled='true'] {
    opacity: var(--disabled-opacity);
    cursor: not-allowed;
  }
}

.button {
  font-size: var(--font-size);
  font-family: var(--font-family);
  font-weight: 600;
  display: inline-flex;
  position: relative;
  line-height: 1;
  align-items: center;

  &__notificationWrapper {
    position: relative;
    display: flex;

    .button__notification {
      position: static;
      transform: translateY(-50%);
    }
  }

  &__notification {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    display: block;
    background-color: var(--white);
    color: var(--primary);
    border-radius: 0.9rem;
    min-width: 1.8rem;
    height: 1.8rem;
    line-height: 1.8rem;
    font-size: 1.2rem;
    padding: 0 0.4rem;
    text-align: center;
  }

  &--wrapper {
    border: none;
    background-color: transparent;
    appearance: none;
    padding: 0;
  }

  &--with-icon.button--with-text {
    padding-left: 1.6rem;

    &:not(.button--icon-reverse) {
      .button__label {
        padding-left: 0.8rem;
        user-select: none;
      }
    }

    &.button--icon-reverse {
      &:not(.button--plain-link) {
        padding-right: 1.6rem;
      }

      .button__notificationWrapper,
      .icon {
        order: 1;
      }

      .button__label {
        padding-left: 0;
        padding-right: 0.8rem;
        user-select: none;
      }
    }
  }

  &--primary {
    @include button;
    color: var(--primary-text);
    background-color: var(--primary);

    &:focus:not(.focus-visible) {
      background-color: var(--primary-dark);
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: var(--primary-dark);
      }
    }
  }

  &--accent {
    @include button;
    color: var(--primary-text);
    background-color: var(--accent);

    &:focus:not(.focus-visible) {
      background-color: var(--accent-dark);
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: var(--accent-dark);
      }
    }
  }

  &--secondary {
    @include button;
    color: var(--primary-text);
    background-color: var(--secondary);

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: var(--secondary-dark);
      }
    }

    &:focus:not(.focus-visible) {
      background-color: var(--secondary-dark);
    }
  }

  &--error {
    @include button;
    color: var(--primary--text);
    background-color: var(--error);

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: var(--error-dark);
      }
    }

    &:focus:not(.focus-visible) {
      background-color: var(--error-dark);
    }
  }

  &--light-transparent {
    @include button;
    color: var(--primary-text);
    background-color: var(--white-10a);

    &:hover {
      background-color: var(--white-20a);
    }
  }

  &--action {
    @include button;
    display: flex;
    color: var(--primary-text);
    align-items: center;
    padding: 1.6rem 2.4rem;
    border-radius: var(--border-radius);
    border: 2px solid var(--accent);
    background-color: var(--accent);
    position: relative;
    white-space: normal;

    h3 {
      line-height: 1.3;
    }

    &::after {
      content: '';
      position: absolute;
      right: 1.6rem;
      top: 0;
      bottom: 0;
      width: 1.2rem;
      height: 1.2rem;
      transform: rotate(-45deg);
      display: block;
      border: solid var(--white);
      border-width: 0 2px 2px 0;
      margin: auto;
    }

    > * + * {
      margin-left: 1.6rem;
    }

    &:hover {
      border: 2px solid var(--white);
    }

    &__description {
      margin-top: 0;
      font-size: 1.2rem;
      opacity: 0.7;
    }
  }

  &--plain-link {
    font-weight: 400;
    border: none;
    background: transparent;
    text-align: center;
    align-items: center;
    padding: 0;
    cursor: pointer;
    color: var(--primary--text);
    text-decoration: underline;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        text-decoration: none;
      }
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  &--primary-link {
    @include link;
    color: var(--primary);
  }

  &--secondary-link {
    @include link;
    color: var(--secondary);

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: var(--secondary-dark);
      }
    }

    &:focus:not(.focus-visible) {
      color: var(--secondary-dark);
    }
  }

  &--error-link {
    @include link;
    color: var(--error);

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: var(--error-dark);
      }
    }

    &:focus:not(.focus-visible) {
      color: var(--error-dark);
    }
  }

  &--disabled {
    cursor: not-allowed;
  }

  &--loading {
    pointer-events: none;
    color: transparent;

    .spinner-wrapper {
      margin: 0;
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
