@import '../../_styles/animations';

.error-message {
  color: var(--error);
  display: flex;
  align-items: center;
  animation-name: fade-in-down;
  animation-duration: 0.1s;

  span {
    font-weight: 500;
    font-size: 1.2rem;
  }

  &.global {
    background-color: var(--error);
    padding: 0.8rem 1.6rem;
    display: flex;
    align-items: center;

    span {
      font-weight: 700;
      font-size: 1.4rem;
      margin-bottom: 0;
      margin-left: 0.6rem;
    }
  }
}
