@import '../../_styles/mixins';
@import '../../_styles/breakpoints';
@import '../../_styles/animations';

.order-overview {
  form {
    display: block;
  }
}

.h2 {
  text-wrap: nowrap;

  .email-icon {
    margin-right: 0.8rem;
  }
  .address-icon {
    margin-left: -0.5rem;
    margin-right: 0.4rem;
  }
  .invoice-icon {
    margin-left: -0.2rem;
    margin-right: 0.7rem;
  }
}

.order-overview__container {
  @include breakpoint-up(tablet) {
    display: flex;
    justify-content: space-between;
  }

  margin-top: 4rem;
}

.order-overview__details {
  margin-bottom: 2.4rem;

  @include breakpoint-up(tablet) {
    width: 50%;
    margin-right: 1.5rem;
  }

  > section {
    padding: 1.6rem;
    border: 0.2rem solid var(--white-10a);
    border-radius: var(--border-radius);
    @include breakpoint-up(tablet) {
      margin-top: 1.6rem;
    }
    margin-top: 2.4rem;
  }

  > .noAddress {
    animation-name: horizontal-shake;
    animation-duration: 0.5s;
    animation-iteration-count: 1;

    @include breakpoint-up(tablet) {
      animation-name: horizontal-shake;
      animation-duration: 0.5s;
      animation-iteration-count: 1;

      margin-top: 1.6rem;
    }
  }

  .error-message {
    margin-top: 1rem;
  }
}

.order-overview__summary {
  border-radius: var(--border-radius);
  border: 0.2rem solid var(--white-10a);

  @include breakpoint-down(tablet) {
    margin-top: 4rem;
  }

  > header {
    padding-left: 1.6rem;
  }
}

.order-overview__summary-table {
  width: 100%;
  margin-top: 2.4rem;

  th {
    font-size: 1.8rem;
    text-align: left;
  }

  tbody {
    th,
    td {
      padding: 0.4rem 1.6rem;
      line-height: 1;
    }
  }

  tfoot {
    th,
    td {
      padding: 2rem;
      line-height: 1;
    }
  }

  small {
    font-size: 1.2rem;
    font-weight: 400;
  }

  td.summary__amount {
    padding-right: 0;
  }

  .summary__shipping {
    td {
      text-align: right;
    }
    padding: 1rem;
  }

  tfoot > :not(.summary__total) {
    @include divider();
    background-position: center 0.4rem;

    &:first-child {
      background-position: center 1.6rem;
    }

    th,
    td {
      &:first-child {
        padding-top: 3.2rem;
      }
    }

    td {
      white-space: nowrap;
    }
  }
}

.summary__product-unit-price {
  font-variant-numeric: tabular-nums;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

.summary__product-total,
.summary__amount {
  width: 0;
  white-space: nowrap;
  font-variant-numeric: tabular-nums;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

.summary__product-title {
  text-align: left;
}

.summary__discount td {
  text-indent: -1ch;
}

.summary__total {
  th {
    background-color: var(--white-10a);
    border-radius: 0 0 0 6px;
  }

  td {
    font-weight: 600;
    white-space: nowrap;
    text-align: right;
    background-color: var(--white-10a);
    border-radius: 0 0 6px 0px;
  }
}

.summary__product:not(:first-child) {
  th {
    @include divider();
    background-position: center 1.6rem;
    padding-top: 3.2rem;
  }
}
.order-overview__disclaimer {
  margin-top: 1.6rem;
}

.order-overview__conditions {
  padding: 1.4rem;
  border-radius: var(--border-radius);
  background-color: var(--black-transparent);
  margin-top: 2.4rem;

  label {
    display: flex;
    align-items: center;

    input[type='checkbox'] {
      flex-shrink: 0;
      margin-right: 2rem;
      margin-left: 1rem;
    }
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;
  }

  &,
  & a {
    font-size: 1.6rem;
  }
}

.order-overview__conditions-error {
  animation-name: horizontal-shake;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
}

.po-number-input {
  margin-top: 2.4rem;
}

.want-invoice-toggle {
  margin-top: 1.5rem;
}

.addressToggle {
  margin-top: 2.4rem;
}
