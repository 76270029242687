/* This file overwrites the default css from the npm package */
$color-disabled: var(--primary-text_dark);
$item-size: 3.25rem;

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker__input-container {
  display: block;
  position: static;
}

.react-datepicker-popper {
  z-index: 100;

  &[data-placement^='bottom'],
  &[data-placement^='top'] {
    margin-top: 1rem;
    .react-datepicker__triangle {
      border-bottom-color: var(--secondary);
      border-top-color: var(--secondary);
      left: 4rem;
      &:before {
        border: 0;
      }
    }
  }
  &[data-placement^='top'] {
    margin-bottom: 7.5rem;
  }
}

.react-datepicker {
  font-family: var(--font-family);
  font-size: 1.4rem;
  background-color: var(--secondary);
  box-shadow: 0.8rem 0.8rem 5.4rem 0 var(--shadow);
  font-weight: normal;
  border: 1px solid var(--secondary);
  border-radius: 2rem;
  margin-top: 0.2rem;

  .react-datepicker__header {
    background-color: transparent;
    border: 0;
    padding-top: 0;
    padding-bottom: 1rem;
  }
  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    border: 0;
    background-size: contain;
    background-repeat: no-repeat;
    width: 2.4rem;
    height: 2.4rem;
    top: 1.6rem;
  }
  .react-datepicker__navigation--previous {
    background-image: url('../../_assets/svg/chevron_left.svg');
    left: 2rem;
  }
  .react-datepicker__navigation--next {
    background-image: url('../../_assets/svg/chevron_right.svg');
    right: 2rem;
  }

  .react-datepicker__month {
    margin: 0;
    padding: 0 1.6rem;
    padding-bottom: calc(1.6rem + 0.3rem);
  }
  .react-datepicker__current-month {
    padding: 1.6rem 0;
    font-size: var(--font-size);
    color: var(--white);
    font-weight: 700;
    text-transform: capitalize;
  }

  .react-datepicker__day {
    height: $item-size;
    width: $item-size;
    line-height: $item-size;
    margin: 0.3rem;
    color: var(--white);
    border-radius: 50%;
  }
  .react-datepicker__day-name {
    width: $item-size;
    line-height: 1.2rem;
    margin: 0.3rem;
    color: $color-disabled;
    font-weight: 700;
    text-transform: capitalize;
  }
  .react-datepicker__day--today {
    background-color: var(--white-20a);
    color: var(--white);
  }
  .react-datepicker__day--keyboard-selected {
    background-color: var(--white-20a);
    color: var(--white);
  }
  .react-datepicker__day:hover,
  .react-datepicker__day--selected {
    background-color: var(--white);
    color: var(--secondary);
    font-weight: 700;
  }
  .react-datepicker__day--outside-month {
    color: $color-disabled;
  }
  .react-datepicker__day--disabled {
    color: $color-disabled;
    pointer-events: none;
  }
}
