@import '../../_styles/breakpoints';
@import '../../_styles/constants';

.selligent-wrapper {
  .top-banner {
    width: 100%;
    margin: auto;
  }
  * {
    max-width: var(--content-width);
  }

  img {
    border-radius: var(--border-radius);
    width: 100%;
  }
}

.categories {
  display: flex;
  justify-content: center;
  margin-top: 2.4rem;

  button {
    border: unset;
    margin: 0 1.6rem;
    font-weight: 700;
    color: var(--white);
    text-decoration: none;
    border-bottom: 3px solid transparent;
    font-size: 2rem;
    background-color: transparent;
    transition: 0.2s ease-out;

    &:not(.active) {
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
    .icon {
      margin-left: 0.5rem;
    }
  }

  .active {
    border-bottom: 3px solid var(--primary);
  }

  //Very small screens
  @media only screen and (max-width: 340px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    :first-child {
      margin-bottom: 0.5rem;
    }
  }
}

.product-overview {
  margin-top: 2.4rem;

  > header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    @media only screen and (max-width: 340px) {
      justify-content: center;
    }

    h1 {
      font-size: 1.6rem;

      @include breakpoint-down(mobile) {
        margin-bottom: 1.6rem;
      }
    }
  }

  &__sort {
    display: flex;
    align-items: center;
    @include breakpoint-down(mobile) {
      width: 100%;
    }

    @media only screen and (max-width: 340px) {
      flex-direction: column;
      align-items: start;
    }

    .input-wrapper-label {
      margin-bottom: 0;
      white-space: nowrap;
    }

    .dropdown {
      margin-left: 1.6rem;
      @media only screen and (max-width: 340px) {
        margin-left: 0;
      }
      min-width: 16rem;
      width: 100%;
    }
  }
}

.product-list {
  margin-top: 1.6rem;

  > * {
    display: flex;
  }

  > * + * {
    margin-top: 0.8rem;
  }

  @include breakpoint-up(tablet) {
    display: flex;
    flex-wrap: wrap;

    > * {
      flex: 0 0 calc(50% - 1.2rem);
      margin-top: 0;
    }

    > *:nth-child(even) {
      margin-left: 2.4rem;
    }

    > *:nth-child(n + 3) {
      margin-top: 2.4rem;
    }
  }

  &--loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30rem;
  }

  &--empty {
    margin-top: 2.4rem;
    text-align: center;
  }
}

.faq {
  margin-top: 2.4rem;
  text-align: center;

  .button {
    margin-top: 1.6rem;
  }
}
