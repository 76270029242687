@import '../../../_styles/breakpoints';

.modal__box {
  @include breakpoint-up(mobile) {
    min-height: 90vh;
  }
}

.modal__content {
  overflow-x: auto;
  h2 {
    margin-bottom: 1rem;
  }
  .google-places-container {
    margin-top: 3rem;
  }
}

.no-addresses {
  width: 100%;
  font-weight: bold;
  opacity: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
}
