@import '../../_styles/mixins';
@import '../../_styles/breakpoints';

.delayed-payment {
  display: flex;
  flex-direction: column;
  min-height: 10rem;
  width: 38.5rem;
  margin-top: 5rem;
  @include breakpoint-up(tablet) {
    width: 25rem;
    margin-top: 0;
  }
  line-height: 1.7;

  &--loading &__control__check,
  &--loading p {
    opacity: 0.2;
  }

  &__control {
    position: relative;
    padding-left: 0.5rem;
    margin-left: 1.5rem;
    @include breakpoint-up(tablet) {
      margin-left: 0;
    }
    display: flex;
    flex-direction: column;

    &__check {
      margin: 1.6rem 0 1.6rem 0;
      label {
        align-items: center;
      }
    }
    &__radio {
      display: flex;
      flex-direction: row;
      align-items: center;
      &__text {
        @include breakpoint-up(tablet) {
          margin-left: 1.2rem;
        }
      }
    }

    &__info {
      margin-left: 0.5rem;
      max-width: 25rem;
    }
    &__button {
      display: flex;
      width: 100%;
      justify-content: center;
      margin: 1.6rem 0 1.6rem 0;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      @include divider();
    }
  }
}
