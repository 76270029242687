@import '../../_styles/colors';

.icon {
  display: inline-block;
  vertical-align: top;

  svg {
    display: block;
    width: var(--width);
    height: var(--height);
  }
}
