/**
* The breakpoints defined here should be changed per project.
* It also depends whether you design mobile-first or dektop-first.
* Mobile first: use 'min-width' in the media queries
* Desktop first: use 'max-width' in the media queries
*/

$breakpoint-big-monitor: 1920px;
$breakpoint-monitor: 1280px;
$breakpoint-desktop: 1025px;
$breakpoint-tablet: 864px;
$breakpoint-mobile: 632px;
$breakpoint-mobile-small: 400px;

$breakpoint-max-fix: 0.02;

@mixin breakpoint-down($point) {
  @if $point == mobile-small {
    @media (max-width: $breakpoint-mobile-small - $breakpoint-max-fix) {
      @content;
    }
  } @else if $point == mobile {
    @media (max-width: $breakpoint-mobile - $breakpoint-max-fix) {
      @content;
    }
  } @else if $point == tablet {
    @media (max-width: $breakpoint-tablet - $breakpoint-max-fix) {
      @content;
    }
  } @else if $point == desktop {
    @media (max-width: $breakpoint-desktop - $breakpoint-max-fix) {
      @content;
    }
  } @else if $point == monitor {
    @media (max-width: $breakpoint-monitor - $breakpoint-max-fix) {
      @content;
    }
  } @else if $point == big-monitor {
    @media (max-width: $breakpoint-big-monitor - $breakpoint-max-fix) {
      @content;
    }
  }
}

@mixin breakpoint-up($point) {
  @if $point == mobile-small {
    @media (min-width: $breakpoint-mobile-small) {
      @content;
    }
  } @else if $point == mobile {
    @media (min-width: $breakpoint-mobile) {
      @content;
    }
  } @else if $point == tablet {
    @media (min-width: $breakpoint-tablet) {
      @content;
    }
  } @else if $point == desktop {
    @media (min-width: $breakpoint-desktop) {
      @content;
    }
  } @else if $point == monitor {
    @media (min-width: $breakpoint-monitor) {
      @content;
    }
  } @else if $point == big-monitor {
    @media (min-width: $breakpoint-big-monitor) {
      @content;
    }
  }
}

@mixin breakpoint-only($point) {
  @if $point == mobile-small {
    @media (min-width: $breakpoint-mobile-small) and (max-width: $breakpoint-mobile - $breakpoint-max-fix) {
      @content;
    }
  } @else if $point == mobile {
    @media (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet - $breakpoint-max-fix) {
      @content;
    }
  } @else if $point == tablet {
    @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop - $breakpoint-max-fix) {
      @content;
    }
  } @else if $point == desktop {
    @media (min-width: $breakpoint-desktop) and (max-width: $breakpoint-monitor - $breakpoint-max-fix) {
      @content;
    }
  } @else if $point == monitor {
    @media (min-width: $breakpoint-monitor) and (max-width: $breakpoint-big-monitor - $breakpoint-max-fix) {
      @content;
    }
  } @else if $point == big-monitor {
    @media (min-width: $breakpoint-big-monitor) {
      @content;
    }
  }
}

@mixin breakpoint-between($pointMin, $pointMax) {
  @if $pointMin == mobile-small and $pointMax == mobile {
    @media (min-width: $breakpoint-mobile-small) and (max-width: $breakpoint-mobile - $breakpoint-max-fix) {
      @content;
    }
  } @else if $pointMin == mobile and $pointMax == tablet {
    @media (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet - $breakpoint-max-fix) {
      @content;
    }
  } @else if $pointMin == tablet and $pointMax == desktop {
    @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop - $breakpoint-max-fix) {
      @content;
    }
  } @else if $pointMin == desktop and $pointMax == monitor {
    @media (min-width: $breakpoint-desktop) and (max-width: $breakpoint-monitor - $breakpoint-max-fix) {
      @content;
    }
  } @else if $pointMin == monitor and $pointMax == big-monitor {
    @media (min-width: $breakpoint-monitor) and (max-width: $breakpoint-big-monitor - $breakpoint-max-fix) {
      @content;
    }
  }
}
