form {
  --gap: 1.6rem;

  &,
  fieldset {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--gap);
  }

  .disclaimer,
  .input-wrapper,
  .button {
    grid-column: span 4;

    &.input-25 {
      grid-column: span 1;
    }
    &.input-50 {
      grid-column: span 2;
    }
    &.input-75 {
      grid-column: span 3;
    }
  }

  fieldset {
    grid-column: span 4;
    margin-top: 3.2rem;
  }

  legend + * {
    margin-top: var(--gap);
  }
}
