.bulk-pricing {
  border-radius: var(--border-radius);
  background-color: var(--header-gradient-start);
  padding: 2.4rem;

  table {
    width: 100%;
  }

  &__label {
    text-align: left;
    width: 40%;
  }

  &__unit-price {
    text-align: right;
    width: 60%;
  }

  td {
    color: var(--grey-400);
  }
}
