.timeline {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
}

.timeline-item {
  position: relative;
  width: 2rem;
  height: 2rem;

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 2rem;
    height: 2rem;
    background-color: transparent;
    border: 3px solid var(--grey-700);
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  &.completed:before {
    border-color: var(--success);
    background-color: var(--success);
  }

  &.active:before {
    border-color: var(--success);
    background-color: var(--success);
  }

  &.next-active:before {
    border-color: var(--accent);
  }

  .timeline-label {
    position: absolute;
    top: -2rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1rem;
    white-space: nowrap;
  }

  &:first-child .timeline-label {
    left: 0;
    transform: translateX(0%);
  }

  &:last-child .timeline-label {
    left: auto;
    right: 0;
    transform: translateX(0%);
  }

  .timeline-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.timeline-line {
  flex: 1;
  height: 4px;
  background-color: var(--grey-700);
  align-self: center;
  &.dashed {
    background-color: transparent;
    border-top: 4px dashed var(--accent);
    height: 0;
  }
  &.completed {
    background-color: var(--success);
  }
}
