@import '../../_styles/breakpoints';

.dropdown .dropdown__control .dropdown__value-container {
  padding-right: 1.8rem;
  padding-left: 1rem;
}

.pagination {
  width: 100%;
  @include breakpoint-up(tablet) {
    display: flex;
    justify-content: space-between;
  }

  &__limit {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;

    .dropdown__control {
      height: auto;
      min-height: 0;
      cursor: pointer;
    }

    > span {
      margin-right: 1rem;
    }
  }
  &__navigation {
    display: flex;
    align-items: center;

    ul {
      display: flex;
      align-items: center;

      li {
        @include breakpoint-up(mobile) {
          margin: 0 1rem;
        }

        &.current-page button {
          background-color: var(--primary-text);
          color: var(--background);
          justify-content: center;
          align-items: center;
          display: flex;

          &:focus:not(.focus-visible),
          &:hover {
            background-color: var(--primary-text-dark);
            color: var(--background);
          }
        }

        button {
          cursor: pointer;
          font-family: var(--font-family);
          font-size: 1.6rem;
          border: none;
          border-radius: 50%;
          background-color: transparent;
          color: var(--primary-text);
          font-weight: 400;
          width: 2rem;
          height: 2rem;
          justify-content: center;
          align-items: center;
          display: flex;

          &:focus:not(.focus-visible),
          &:hover {
            color: var(--primary-text-dark);
          }
        }
      }
    }

    .nav-button {
      text-decoration: none;
      padding-left: 0;

      &:first-of-type {
        margin-right: 0.6rem;
      }

      &:last-of-type {
        margin-left: 0.6rem;
      }
      .icon {
        transition: 100ms ease-out transform;
      }

      &:hover:not(&:disabled),
      &:focus:not(.focus-visible):not(&:disabled) {
        color: var(--primary-text-dark);
        .icon {
          transform: scale(1.2);
        }
      }
    }
  }

  @include breakpoint-down(tablet) {
    margin-top: 2.4rem;
    &__navigation {
      justify-content: space-between;
      .nav-button {
        padding: 1rem 1.6rem;
      }
    }
  }
}
