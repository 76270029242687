@import './_styles/reset';
@import './_styles/colors';
@import './_styles/constants';
@import './_styles/breakpoints';
@import './_styles/form.scss';

html {
  // This value defines the value of '1rem'
  font-size: 62.5%;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primary-text);
  background-color: var(--background);
}

body {
  font-family: var(--font-family);
  font-size: var(--font-size);
  line-height: 1.7;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

#root {
  display: grid;
  flex-direction: column;
  min-height: 100vh;
  grid-template-rows: min-content 1fr min-content;
}

input,
textarea {
  font-family: inherit;
}

h1,
.h1 {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 1.4;
}

h2,
.h2 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 1;
}

h3,
.h3 {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.7;
}

h4,
.h4 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.5;
}

h5,
.h5 {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.3;
}

h6,
.h6 {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.5;
}

a:not(.button) {
  color: var(--primary-text);
  text-decoration: none;
  &:hover {
    color: var(--primary-text_dark);
  }
  &[aria-disabled='true'],
  &[disabled] {
    pointer-events: none;
    opacity: var(--disabled-opacity);
  }
}

.visually-hidden:not(:focus:not(.focus-visible)):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

main {
  width: 100vw;
  max-width: calc(var(--content-width) + 1.6rem + 1.6rem);
  padding: 1.6rem;
  margin: 0 auto;
}

.fill-width {
  @include breakpoint-down(tablet) {
    margin-left: -1.6rem;
    margin-right: -1.6rem;
  }
}

.container {
  max-width: 1500px;
  margin: auto;
  padding: 0 1.6rem;
}

.scroll-lock {
  overflow: hidden;
  overflow: clip;
}

.content-block {
  margin-top: 2.4rem;

  :is(ul, p, h1, h2, h3, h4, h5, h6) + :is(p, ul),
  p + :is(h1, h2, h3, h4, h5, h6) {
    margin-top: 1.6rem;
  }

  ul {
    li {
      position: relative;
      padding-left: 0.8rem;

      &::before {
        content: '';
        background-color: var(--white);
        border-radius: 50%;
        width: 0.4rem;
        height: 0.4rem;
        display: block;
        position: absolute;
        top: 1ch;
        left: 0;
      }
    }

    li + li {
      margin-top: 3.2rem;
    }
  }

  li {
    :is(h1, h2, h3, h4, h5, h6) + p {
      margin-top: 0.8rem;
    }
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

:is(h1, h2, h3, h4, h5, h6) + p {
  margin-top: 1.6rem;
}

.empty-screen {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-transition {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

//Custom Scroll bar

//Chrome, Safari, Edge
*::-webkit-scrollbar {
  width: 0.7rem;
}

*::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color);
}

*::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
  border-radius: 0.5rem;
}

*::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-hover-thumb-color);
  border-radius: 0.5rem;
}
