.textarea-wrapper {
  .textarea {
    width: 100%;
    outline: none;
    border-radius: none;
    padding: 1rem;
    border: 1px solid var(--secondary-text);
    color: var(--primary-text);
    font-size: var(--font-size);
    font-family: var(--font-family);
    box-sizing: border-box;
    resize: vertical;

    &:focus:not(.focus-visible) {
      border: 1px solid var(--primary);
    }

    &:disabled {
      opacity: var(--disabled-opacity);
      background-color: var(--input-background);
    }

    &::placeholder {
      color: var(--secondary-text);
    }

    &.error {
      color: var(--error);
      border-color: var(--error);
      background-color: var(--input-background);

      &::placeholder {
        color: var(--error);
      }

      &:focus:not(.focus-visible) {
        border-color: var(--error);
      }
    }
  }

  .character-limit {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--primary-text_dark);
    font-size: 1.4rem;

    &.reached {
      color: var(--error);
    }
  }
}
