@import '../_styles/mixins';

.landing-page {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--background);

  @supports (backdrop-filter: blur(50px)) {
    background: var(--background-transparent);
    mix-blend-mode: normal;
    backdrop-filter: blur(50px);
  }

  font-size: 1.6rem;

  .shop-preference-group {
    width: 28.8rem;
    position: relative;
    padding: 2.5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    & + .shop-preference-group::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      @include divider();
    }
    a {
      margin-top: 2.4rem;
    }
  }
}
