.language-picker {
  ul {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.6rem;
  }

  li:not(:first-child) {
    &:before {
      content: '';
      padding-left: 0.8rem;
      margin-left: 0.8rem;
      border-left: 1px solid currentColor;
      height: 1em;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
