@import '../_styles/breakpoints';
@import '../_styles/mixins';
@import '../_styles/animations';

.modal-open {
  overflow: hidden;
}

.modal {
  display: grid;
  place-items: center;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;

  --padding: 1.6rem 0.8rem;
  --button-padding: 1.2rem 1.6rem;
  @include breakpoint-up(tablet) {
    --padding: 2.4rem 5.6rem 1.6rem;
  }

  @include breakpoint-down(mobile) {
    place-items: flex-end center;
  }

  &__close {
    cursor: pointer;
    color: var(--white);
    position: relative;
    z-index: 1;
    display: none;
    width: auto;
    position: absolute;
    right: 3rem;
    top: 3rem;
    z-index: 2;

    @include breakpoint-up(mobile) {
      display: block;
    }
  }

  &__dimmer {
    appearance: none;
    border: 0;
    background-color: var(--modal-dimmer);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    animation: fade-in 0.25s ease-out;
  }

  &__box {
    display: grid;
    grid-template-areas:
      'title'
      'content'
      'footer';
    grid-template-rows: min-content auto min-content;

    pointer-events: initial;
    width: 85rem;
    height: 100%;
    position: relative;
    overflow-y: auto;
    background-color: var(--secondary-dark);
    max-width: 100vw;
    border-radius: 1rem;

    @include breakpoint-up(tablet) {
      display: flex;
      flex-direction: column;
      height: auto;
      max-height: calc(100vh - 10rem);
      max-width: 96vw;
    }
  }

  &__header {
    padding: var(--padding);
    grid-column: title;
    grid-row: 1;
    position: sticky;
    top: 0;
    background-color: inherit;
    z-index: 1;
    text-align: center;

    @include breakpoint-down(mobile) {
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      display: flex;
      align-items: center;
    }

    &__navigation {
      color: var(--white);
      width: 50px;
      min-height: 40px;
    }

    @include breakpoint-down(mobile) {
      &::after {
        content: '';
        @include divider();
        bottom: 0;
        height: 1px;
        position: absolute;
        left: 0;
        width: 100%;
      }
    }

    h2 {
      @include breakpoint-down(mobile) {
        font-size: 1.6rem;
      }
      @include breakpoint-up(mobile) {
        text-align: center;
      }
    }
  }

  &__content {
    padding: var(--padding);
    padding-top: 4rem;
    padding-bottom: 4rem;

    @include breakpoint-up(tablet) {
      &:not(:first-child) {
        padding-top: 0;
      }
    }

    flex: 1;
  }

  &__buttons {
    position: sticky;
    bottom: 0;
    padding: var(--button-padding);
    flex: 0;
    display: flex;
    justify-content: flex-end;
    background-color: inherit;
    flex-shrink: 0;

    @include breakpoint-down(mobile) {
      > * {
        width: 100%;
        justify-content: center;
      }
    }

    @include breakpoint-up(tablet) {
      background-color: var(--background);
    }

    > :not(:first-child) {
      margin-left: 1.5rem;
    }
  }
}
