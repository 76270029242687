@import '../../../../_styles/breakpoints';

.product__cross-selling {
  margin-top: 5rem;
  padding: 2.4rem;
  background-color: var(--cross-sell);
  text-align: center;

  @include breakpoint-up(tablet) {
    border-radius: var(--border-radius);
  }

  footer {
    margin-top: 2.4rem;
  }

  .banner {
    padding-top: 2.4rem;
    max-width: 100%;
    margin: auto;
  }

  img {
    border-radius: var(--border-radius);
    max-height: 20rem;
    max-width: 100%;
  }
}

.empty {
  margin-top: 8rem;
}
