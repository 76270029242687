@import '../../_styles/breakpoints';

.account-info-loading {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.account-info {
  h1 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  h2:first-of-type {
    margin-top: 1rem;
  }

  h2 {
    margin-top: 2.4rem;
    font-size: 1.6rem;
  }

  ul {
    margin-top: 0.8rem;
  }

  > .back-to-shop-wrapper {
    display: flex;
    justify-content: flex-start;
  }

  .back-button {
    padding-left: 0;
  }

  .account-info__section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__reset {
    padding: 1rem 1rem 1rem 0;
  }

  &__invoice-details {
    margin-top: 1rem;
    margin-right: 0.5rem;
    background-color: var(--table);
    margin-bottom: 1rem;
    border-radius: var(--border-radius);
    border: 0.2rem solid var(--white-10a);
    display: flex;
    flex-direction: row;
    align-items: center;

    &__user-icon {
      height: 100%;
      display: flex;
      justify-content: center;
      margin-left: 1.8rem;
    }

    > section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 2rem;
      border-radius: var(--border-radius);

      > .actionable-header {
        margin-bottom: 1rem;
      }
    }
  }

  &__address-list {
    max-height: 30rem;
    overflow: auto;
    padding-right: 1rem;
  }

  &__no-results {
    margin-top: 0.8rem;
  }

  &__address-line {
    &:not(:first-child) {
      margin-top: 0.8rem;
    }

    .chip {
      margin-right: 1rem;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--table);
    padding: 0.4rem;
    padding-left: 1.6rem;
    border-radius: var(--border-radius);
    height: 4.8rem;

    @include breakpoint-down(mobile) {
      padding: 0.8rem 1.6rem;
      margin-top: 0.8rem;
    }
  }

  .actions-wrapper {
    display: flex;
    flex-direction: row;
  }

  address {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;

    span {
      display: inline;
    }

    span:not(:last-child):not(.icon):after {
      content: ', ';
    }
  }

  .address__add {
    height: 3.8rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;

    @include breakpoint-up('mobile') {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    @include breakpoint-down('mobile') {
      min-width: 97%;
      justify-content: center;
    }
  }
}

.guest {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__login-button {
    align-items: center;
    justify-content: center;
    height: 5rem;
    width: 15rem;
  }
}

.no-company-selected {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .pick-company-button {
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;
    border-radius: var(--border-radius);
    background-color: var(--primary);

    &:hover {
      background-color: var(--primary-dark);
    }

    .icon {
      margin-right: 1rem;
    }
  }
}

.delete-address {
  margin-right: 1rem;
}

.inline-icon-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    margin-right: 0.5rem;
  }

  .icon {
    margin-right: 1rem;
  }

  .pickup-point-icon {
    margin-left: 0.25em;
    margin-right: 0.85em;

    @include breakpoint-down('mobile') {
      margin-left: unset;
      margin-left: 0.2em;

      margin-right: unset;
      margin-right: 0.9em;
    }
  }
}
