.announcement {
  width: 100vw;
  background-color: var(--announce-background, var(--warning));
  text-align: center;

  > div {
    padding: 1.6rem;
    position: relative;
    padding-right: 4.8rem;
  }

  .content-block {
    margin: 0;
  }

  p {
    color: var(--announce-text, var(--primary-text));
  }
}

.announcement__close {
  position: absolute;
  top: 1.8rem;
  right: 1.6rem;
  padding: 0.3rem;
}
