@import '../../_styles/breakpoints';

.shopping-cart-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 1.6rem 1.6rem;

  > div {
    display: flex;
    align-items: center;
  }

  .button {
    margin-left: 0.8rem;
    transition: 150ms ease-out transform;

    &:hover,
    &:focus {
      transform: scale(1.2);
    }
  }

  &__label {
    font-weight: 700;
  }

  &__description {
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.3;
    margin-left: 0.8rem;
  }

  &.highlight {
    background-color: var(--white-10a);

    .shopping-cart-line__value {
      font-weight: 700;
    }
  }

  @include breakpoint-down(mobile) {
    padding: 2rem 0 1.6rem;

    &.highlight {
      padding: 2rem 1.6rem 1.6rem;
      margin: 0 -1.6rem;
    }

    > div {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .shopping-cart-line__description {
        margin-left: 0;
      }

      .button {
        margin-left: 0;
        margin-top: 0.8rem;
      }
    }
  }
}
