.mobile-orders-list {
  margin-top: 1rem;
  &__empty {
    text-align: center;
  }

  li {
    position: relative;
    background-color: var(--table);
    margin: 0 0 2rem 0;
    padding: 1.6rem;
    border-radius: 0.5rem;

    .mobile-orders-list__order-number {
      display: flex;
      flex-direction: row;
    }

    span {
      display: block;

      .mobile-orders-list__date {
        font-weight: 700;
        margin-bottom: 0.8rem;
      }

      .mobile-orders-list__status {
        position: absolute;
        top: 1.6rem;
        right: 1.6rem;
      }
      .mobile-orders-list__status-icon {
        margin-right: 0.8rem;
      }
    }
  }
}
