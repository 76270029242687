@import '../_styles/breakpoints';

.checkout {
  margin-top: 4rem;

  .page-title {
    text-align: center;
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
  grid-column: span 4;
  width: 100%;

  &--stacked {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    margin-top: 2rem;

    > * {
      width: 100%;
      margin: auto;
      justify-content: center;
      margin-top: 1.6rem;
    }
  }

  .action__back {
    min-width: 17rem;
    justify-content: center;
  }

  .action__confirm {
    order: 1;
    min-width: 17rem;
    justify-content: center;
  }

  @include breakpoint-down(mobile) {
    .action__confirm {
      width: 100%;
      justify-content: center;
    }
  }
}

.actionable-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.6rem;
  h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tooltip__trigger {
      margin-left: 0.5rem;
    }
  }
}

.disclaimer {
  font-size: 1.4rem;
  grid-column: span 4;
}

.disclaimer--phone {
  margin-top: -0.8rem;
}

.shipping {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0.8rem auto 0;
  form,
  fieldset {
    grid-column: span 4;
  }

  &--digital:not(&--physical) &__invoice-address {
    margin-top: 0;
  }
}

.checkout-empty {
  margin: 4rem 0;
  display: grid;
  place-items: center;
}
