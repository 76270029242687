@import '../../_styles/breakpoints';

form {
  width: 100%;
}

.disclaimer {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
}

.guest-email-wrapper {
  margin-top: 0;
  margin-bottom: 2rem;
  width: 100%;
}

.phone-numbers {
  @include breakpoint-down(tablet) {
    display: flex;
    flex-direction: column;
  }
}

.box {
  .label {
    text-wrap: nowrap;
  }
}
