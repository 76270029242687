@import '../../../../_styles/breakpoints';

.product--detail__header {
  position: relative;

  .product__order-information {
    text-align: center;
    @include breakpoint-up(mobile) {
      display: none;
    }
  }

  h1 {
    text-align: center;
    position: relative;
    z-index: 1;

    @include breakpoint-up(mobile) {
      display: none;
    }
  }

  figure {
    position: relative;
    aspect-ratio: 1/1;
    display: flex;

    @include breakpoint-down(mobile) {
      margin: 20px 0;
    }

    img {
      display: block;
      margin: auto;
      position: relative;
      z-index: 2;
    }

    &::before {
      content: '';
      background-image: url('../../../../_assets/svg/star.svg');
      background-size: 120%;
      background-position: center center;
      background-repeat: no-repeat;
      position: absolute;
      top: -40px;
      left: 0;
      right: 0;
      bottom: -40px;
      z-index: 1;
      max-width: 320px;
      margin: auto;
      pointer-events: none;
    }
  }
}
