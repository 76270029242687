@import '../../_styles/breakpoints';
@import '../../_styles/colors';

.orders-overview,
main {
  @include breakpoint-up(desktop) {
    max-width: 100vh;
  }
}

> .back-to-shop-wrapper {
  display: flex;
  justify-content: flex-start;
}
.back-button {
  padding-left: 0 !important;
}

h1 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.pagination {
  margin-top: 1rem;
}

.guest {
  height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__login-button {
    align-items: center;
    justify-content: center;
    height: 5rem;
    width: 15rem;
  }
}

.orders-overview {
  width: 100%;

  &__icon {
    margin-right: 0.5rem;
  }
  h1 {
    display: flex;
    align-items: center;
    margin-bottom: 2.4rem;
  }
  h2 {
    .icon {
      margin-right: 1.2rem;
    }
  }

  > .spinner-wrapper {
    margin: auto;
    width: 100%;
  }

  table {
    min-width: 900px;
    width: 100%;
    height: 100%;
    margin: 0 auto;

    tbody {
      .status > a {
        position: relative;
        align-items: center;
        justify-content: baseline;
        display: flex;
        flex-direction: row;

        .status-icon {
          margin-right: 1rem;
        }
      }
    }
  }
}
