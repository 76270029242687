@import '../../../_styles/breakpoints';

.not-found {
  z-index: 10;
  height: 100%;
  min-height: 60vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    text-align: center;
    font-size: 5rem;
  }
  .home-button {
    z-index: 10;
  }
}

.backdrop {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @include breakpoint-down('tablet') {
    height: 80%;
  }

  &__logo {
    z-index: -1;
    opacity: 0.1;
    height: 75%;
    width: 75%;
    user-select: none;
    pointer-events: none;

    @include breakpoint-down('tablet') {
      height: 90%;
      width: 90%;
    }
  }
}
