@import '../../_styles/breakpoints';

.edit-personalization {
  display: flex;
  align-items: flex-start;
  margin-top: 1rem;
  line-height: 3rem;

  &__prefix {
    white-space: nowrap;
  }

  &__input {
    width: 100%;
    max-width: 30rem;

    > .input {
      height: 3rem;
      padding: 0.6rem 0.8rem 0.6rem;
    }
  }

  &__button.button--with-text {
    font-size: 0;
    padding: 0.6rem 0.8rem;
    width: 3.4rem;
  }
}
