.google-places-container {
  position: relative;
  width: 100%;
  grid-column: 1 / -1;
}

.google-places-input {
  width: 100%;
  column-span: all;
  outline: none;
  text-align: left;
  padding: 1.4rem 4.5rem;

  background: var(--input-background);
  border: 1px solid var(--dark-labels);
  color: var(--primary-text);
  border-radius: 4rem;
  font-size: var(--font-size);
  box-sizing: border-box;
  height: var(--input-height);
  z-index: 10;

  &:focus {
    border-color: var(--primary-text);
  }
}

.autocomplete-dropdown-container {
  position: absolute;
  width: 100%;
  top: 100%;
  z-index: 10;
  border-radius: var(--border-radius);
  background-color: var(--primary-text);
  margin-top: 0.4rem;

  .search-result-icon {
    margin-right: 0.5rem;
  }

  .suggestion-item {
    padding: 1rem 2rem 1rem 1rem;
    cursor: pointer;
    color: black;

    &:not(:first-child) {
      border-top: 0.1rem solid var(--grey-400);
    }

    &:hover {
      background-color: var(--grey-400);
      &:first-child {
        border-radius: var(--border-radius) var(--border-radius) 0 0;
      }
      &:last-child {
        border-radius: 0 0 var(--border-radius) var(--border-radius);
      }
    }
  }
}

.places-icon {
  position: absolute;
  top: 50%;
  left: 1.4rem;
  transform: translateY(-50%);
  z-index: 10;
}

.loading {
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%) translateX(50%);
  z-index: 10;
}
