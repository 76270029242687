.table-wrapper {
  overflow-x: auto;
  width: 100%;
}

.table {
  table-layout: fixed;
  width: calc(100% - 1px);

  thead {
    padding: 0 0.4rem;

    th {
      padding: 0.8rem 1.2rem;
      border-bottom: 1px solid var(--primary-text);

      span {
        display: block;
        font-size: 1.2rem;
        line-height: 1.3;
        text-align: left;
        font-weight: bold;
        text-transform: uppercase;
        color: var(--primary-text);
      }

      > button {
        border: none;
        background-color: transparent;
        display: flex;
        align-items: center;
      }

      &.text-align {
        &--left {
          text-align: left;
          > div {
            justify-content: flex-start;
          }
        }
        &--center {
          text-align: center;
          > div {
            justify-content: center;
          }
        }
        &--right {
          text-align: right;
          > div {
            justify-content: flex-end;
          }
        }
      }

      &.sortable {
        padding: 0;

        button {
          padding: 0.8rem 1.2rem;
          cursor: pointer;

          .sort-arrow {
            color: var(--grey-200);
            margin-left: 0.5rem;
            transition: 100ms ease-out transform, 100ms ease-out color;
          }
        }

        &.asc {
          .sort-arrow {
            transform: rotate(180deg);
          }
        }

        &:hover,
        &:focus-within {
          border-bottom-color: var(--primary-text-dark);

          span {
            color: var(--primary-text-dark);
          }

          .sort-arrow {
            transform: scale(1.1), rotate(0deg);
            color: var(--grey-400);
          }
        }
      }
    }
  }

  tbody {
    tr {
      padding: 0 0.4rem;
      background-color: var(--table-50);
      border: 1px solid var(--background);
      border-bottom-width: 2px;

      &:focus-within,
      &:hover {
        background-color: var(--table);
      }
    }

    td {
      overflow: hidden;
      font-weight: normal;
      line-height: 1.5;
      font-feature-settings: 'tnum' on, 'lnum' on;
      text-overflow: ellipsis;
      color: var(--primary-text);

      > a,
      > span,
      > div {
        display: block;
        padding: 1.1rem 1.2rem 1.3rem;
      }

      &.text-align {
        &--left {
          text-align: left;
        }
        &--center {
          text-align: center;
        }
        &--right {
          text-align: right;
        }
      }

      &.table-empty {
        padding: 2rem;
        font-size: 1.4rem;
      }
    }

    tr.row-spinner {
      border-top: 0;

      .spinner-wrapper {
        padding: 1.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
