@import '../../../../_styles/breakpoints';

.product-details__contents {
  border: 1px solid var(--white);
  border-radius: var(--border-radius);
  padding: 2.4rem 1.6rem;

  @include breakpoint-up(tablet) {
    ul {
      display: flex;
      flex-wrap: wrap;
    }
  }
  @include breakpoint-down(tablet) {
    ul {
      display: flex;
      flex-direction: column;
    }
  }

  li {
    @include breakpoint-up(tablet) {
      width: calc(50% - 2.4rem);

      &:nth-child(even) {
        margin-left: 4.8rem;
      }

      &:nth-child(n + 3) {
        margin-top: 1.6rem;
      }
    }

    @include breakpoint-down(tablet) {
      width: 100%;

      & + li {
        margin-top: 1.6rem;
      }
    }

    > * + * {
      margin-left: 1.6rem;
    }
  }

  > h2 {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 2.4rem;
  }

  svg {
    color: var(--accent);
  }
}

.product__contents {
  &__products {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  li {
    display: flex;
    align-items: center;
  }

  p {
    margin-top: 0;
    font-size: 1.3rem;
    line-height: 1.3;
    color: var(--grey-200);
  }
}
