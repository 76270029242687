@import '../../../_styles/breakpoints.scss';
@import '../../../_styles/colors.scss';

.mobile-table {
  &__products {
    overflow-y: auto;
    max-height: 48rem;
    margin-bottom: 1rem;
    &:first-of-type {
      margin-top: 1rem;
    }
  }
  &__group {
    border: 2px solid var(--grey-700);
    border-radius: 1rem;
    margin: 0 0.5rem 1rem 0;
    padding: 1rem 1rem 1rem 2rem;
  }
  &__line {
    color: var(--grey-100);
    width: 100%;
    .mobile-table__product-name {
      font-weight: bold;
      font-size: medium;
    }

    .mobile-table__label {
      margin-right: 1rem;
    }
  }

  .global-information {
    li.global-information__row {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1.6rem 1.6rem 1.2rem;
      border-bottom: 0;

      &:first-of-type {
        background-color: var(--white-10a);
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }

      &:last-of-type {
        background-color: var(--white-20a);
        padding-bottom: 1.6rem;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }
    }
  }
}
