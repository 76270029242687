$height-toolbar: 4.3rem;

.quill {
  min-height: calc(#{$height-toolbar} + 5rem);

  .ql-toolbar,
  .ql-container {
    border-color: var(--shadow);
    color: var(--primary-text);
  }

  .ql-container {
    font-size: var(--font-size);
    height: calc(100% - #{$height-toolbar});

    .ql-editor.ql-blank::before {
      font-style: normal;
      color: var(--primary-text);
    }
  }

  &.error {
    .ql-toolbar,
    .ql-container {
      border-color: var(--error);
    }

    .ql-container {
      background: var(--input-background);

      .ql-editor.ql-blank::before {
        color: var(--error);
      }
    }
  }

  &.active {
    > div {
      border-color: var(--primary-text);
    }
    .ql-toolbar,
    .ql-container {
      color: var(--primary-text);
    }
    &.error {
      .ql-toolbar,
      .ql-container {
        border-color: var(--error);
      }
    }
  }

  &.disabled {
    opacity: var(--disabled-opacity);
    > div {
      border-color: var(--shadow);
      background-color: var(--input-background);
      pointer-events: none;
    }
  }
}
