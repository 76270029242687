.product {
  &__information {
    grid-area: information;
  }

  &--card {
    position: relative;
    background: var(--secondary);
    border-radius: var(--border-radius);
    transition: 0.25s ease-in-out;
    border: 2px solid var(--secondary);
    display: flex;
    align-items: center;
    padding: 1.6rem;
    width: 100%;

    &:hover {
      background-color: var(--secondary-dark);
    }

    &__title {
      font-size: 1.8rem;
    }

    &__image {
      flex: 0 0 11rem;
    }

    &__information {
      margin-left: 2.8rem;
    }

    > a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      user-select: none;
      border-radius: var(--border-radius);
    }

    &__contents {
      margin-top: 0.8rem;
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      svg {
        color: var(--white);
        flex: 0 0 auto;
      }

      li {
        width: 100%;
        background-color: var(--white-10a);
        padding: 0.4rem 1.2rem;
        border-radius: var(--border-radius);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-width: 20rem;

        > * + * {
          margin-left: 0.8rem;
        }
      }

      li + li {
        margin-top: 0.4rem;
      }

      p {
        flex: 1;
        font-weight: 600;
        font-size: 1.3rem;
        text-align: center;
      }
    }

    &__price {
      margin-top: 0.4rem;
      font-size: 1.4rem;
      font-weight: 700;
    }
    &__vat {
      font-size: 1rem;
      font-weight: 400;
    }

    //Very small screens
    @media only screen and (max-width: 350px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__image {
        width: 100%;
        margin-bottom: 1.6rem;
        display: flex;
        justify-content: center;
      }

      &__information {
        width: auto;
        margin-left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &--popular {
    border: 0.3rem solid var(--primary);

    &::after {
      content: attr(data-popular-label);
      position: absolute;
      top: 0;
      left: 1.6rem;
      transform: translateY(-50%);
      background-color: var(--primary);
      font-weight: 700;
      text-transform: uppercase;
      font-size: 1rem;
      padding: 0.2rem 0.8rem;
      border-radius: 2rem;
    }
  }
}

.product--card__information {
  width: 100%;
}
