@import '../../../_styles/breakpoints';
@import '../../../_styles/mixins';

.global-footer {
  background-color: var(--footer);
  padding: 2.4rem;
  margin-top: auto;

  &.footer--default {
    display: grid;
    grid-template-columns: 1fr auto auto 1fr;
    align-items: center;
    grid-gap: 2.4rem;
  }

  @include breakpoint-down(tablet) {
    &.footer--default {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      grid-gap: 0;
    }
  }

  > :not(:first-child):not(:last-child) {
    position: relative;
    padding-bottom: 2.4rem;

    @at-root .footer--default > :not(:first-child):not(:last-child) {
      padding-bottom: 0;
      @include breakpoint-down(tablet) {
        padding-bottom: 2.4rem;
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      @include divider();
    }
  }

  > * + * {
    @include breakpoint-down(tablet) {
      margin-top: 2.4rem;
    }
  }

  &.footer--default {
    > :not(:first-child):not(:last-child)::after {
      @include breakpoint-down(tablet) {
        content: '';
      }
      content: unset;
    }
  }

  &.footer--orderflow {
    > * + * {
      margin-top: 2.4rem;
    }
  }

  hr {
    height: 1px;
    @include divider();
    border: 0;
    grid-column: 1 / 5;
    grid-row: 1;
    margin: 0;
    align-self: flex-end;
    transform: translateY(1.2rem);
  }
}

.footer__copyright {
  grid-column: 1;
  grid-row: 2;
  font-size: 1.2rem;
  text-align: center;
  justify-content: flex-end;

  .footer--default & {
    text-align: right;
    white-space: nowrap;
  }

  img {
    display: block;
    width: 3.2rem;
    height: auto;
    margin: auto;
    margin-bottom: 0.8rem;
    border-radius: 0.2rem;
  }

  @at-root .footer--default & img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.8rem;
    margin-bottom: 0;

    @include breakpoint-down(tablet) {
      display: block;
      margin: auto;
    }
  }
}

.footer__support {
  font-size: 1.4rem;
  line-height: 2.4rem;
  text-align: center;
  margin-top: 1.6rem;

  > div {
    margin-top: 1.6rem;
  }

  .footer--b2b & {
    margin-top: 1.6rem;

    > div {
      margin-top: 1.6rem;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      > * + * {
        margin-left: 1.6rem;
      }
    }
  }

  ul {
    margin-top: 0.8rem;
  }

  dl > div {
    display: flex;
    align-items: center;
    justify-content: center;

    > * + * {
      margin-left: 0.8rem;
    }

    span {
      display: inline;
    }
  }
}

.footer__payment-methods,
.footer__social-media {
  .footer--default & {
    display: flex;
    justify-self: center;
    align-items: center;

    @include breakpoint-down(tablet) {
      display: block;
    }
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint-up(tablet) {
      margin-left: 2.4rem;
    }
  }

  li {
    display: flex;
  }

  li + li {
    margin-left: 0.8rem;
  }
}

.footer__payment-methods {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row: 1;

  > h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;

    :first-child {
      margin-right: 0.3rem;
    }
  }

  &__icon {
    height: 2.4rem;
    width: auto;
    border-radius: 0.5rem;
  }

  .googlepay {
    height: 3.1rem;
  }

  @include breakpoint-down(tablet) {
    ul {
      margin-top: 0.8rem;
    }
  }
}

.footer__social-media {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 1;

  h2 {
    font-weight: 400;
  }

  ul {
    margin-top: 0;
    @include breakpoint-down(tablet) {
      margin-top: 1.6rem;
    }
  }
}

.footer__navigation {
  font-size: 1.2rem;
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row: 2;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      * {
        font-size: 1.2rem;
      }
    }

    li:not(:first-child) {
      > :before {
        content: '';
        padding-left: 0.8rem;
        margin-left: 0.8rem;
        border-left: 1px solid currentColor;
        height: 0.8rem;
      }
    }
  }
}

.footer__target-switcher {
  a {
    text-decoration: none;
  }
}
