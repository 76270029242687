@import '../../../_styles/breakpoints.scss';

.products-table {
  thead {
    tr {
      background-color: var(--table);
      th {
        border: none;
      }
    }
  }

  tbody {
    tr {
      border: none;

      &:nth-last-of-type(3),
      &:last-of-type {
        background-color: var(--table);
      }
    }
  }

  .unit-price,
  .amount,
  .total-price {
    text-align: right;

    button {
      align-items: flex-end;
      justify-content: flex-end;
      width: 100%;
    }
  }

  .name {
    width: 22.4rem;
  }
  .unit-price {
    width: 10rem;
    text-align: left;
  }
  .amount {
    width: 5.6rem;
    text-align: left;
  }
  .total-price {
    > span {
      text-align: right;
    }
    width: 18rem;
    text-align: right;
  }
}
