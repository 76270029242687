.image {
  object-fit: contain;
  user-select: none;
}

.empty-image {
  width: 100%;
  height: auto;
  padding: 2rem;
  user-select: none;
}
