@import '../../_styles/breakpoints';

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

@include breakpoint-down(mobile-small) {
  .pickup-point-list__item {
    flex-direction: column;
    align-items: flex-start;

    .button {
      margin-top: 1rem;
      margin-right: 0;
      width: 100%;
    }
  }
}

.pickup-point-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;

  .selected-pickup-point {
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.4rem;
    margin-bottom: 1rem;
    width: 100%;

    &__address {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 0.2rem solid var(--success);
      padding: 1.6rem;
      border-radius: var(--border-radius);
      width: 100%;

      p {
        margin: 0.4rem 0;
      }

      .title {
        width: 100%;
        text-align: center;
        font-weight: bold;
      }

      &__name {
        font-weight: bold;
        font-size: 1.6rem;
        color: var(--primary-text);
        display: flex;
        align-items: center;
        a {
          padding-left: 0;
        }
      }

      &__location {
        font-size: 1rem;
        color: var(--primary-text-dark);
      }
    }

    .no-pickup-point-selected {
      font-weight: bold;
      font-size: 1.6rem;
      color: var(--primary-text);
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
    }
  }

  .search-wrapper {
    display: flex;
    flex-direction: row;

    .search {
      width: 100%;
    }
  }

  .centered-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 43rem;
  }

  .pickup-point-list {
    margin-top: 3rem;
    max-height: 43rem;
    overflow: auto;

    &__item.selected {
      border: 0.2rem solid var(--accent);
      background-color: var(--accent);
    }

    &__item {
      border: 2px solid var(--white-10a);
      border-radius: 0.8rem;
      padding: 1.6rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-right: 1rem;
      align-items: center;

      .button {
        margin-right: 1rem;
      }

      &:hover {
        @include breakpoint-up(mobile) {
          background-color: var(--white-20a);
        }
      }

      &:not(:first-child) {
        margin-top: 1.6rem;
      }

      &__default {
        margin-top: 0.8rem;
        font-weight: 600;
        color: var(--accent);
      }
    }

    .no-results {
      margin-right: 0;
      font-weight: bold;
      font-size: 1.2rem;
      padding: 1.2rem;
      justify-content: center;
      align-items: center;
      color: var(--primary-text-dark);
      border: none;
      &:hover {
        background-color: unset;
      }
    }

    .input-wrapper,
    .checkbox-wrapper {
      width: 100%;
    }

    &__address {
      p {
        margin: 0.4rem 0;
      }

      &__name {
        font-weight: bold;
        font-size: 1.6rem;
        color: var(--primary-text);
        display: flex;
        align-items: center;
      }

      &__street-and-number {
        font-size: 1rem;
        color: var(--primary-text-dark);
      }

      &__city-and-postalcode {
        font-size: 1rem;
        color: var(--primary-text-dark);
      }
    }

    @include breakpoint-up(mobile) {
      br {
        content: '';
        display: inline;
        &::before {
          content: ', ';
        }
      }
    }

    @include breakpoint-up(mobile) {
      address {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 0;
      }
    }
  }
}
