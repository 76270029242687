.chip {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 50rem;
}

.chip-label {
  margin-right: 0.5rem;
}

.chip-remove-btn {
  background: none;
  border: none;
  cursor: pointer;
}
