.order-confirmation {
  margin-top: 2.4rem;
  text-align: center;

  .button {
    max-width: 30rem;
  }

  p + p {
    margin-top: 2.4rem;
  }

  p + .content-block {
    margin-top: 2.4rem;
  }

  .content-block + p {
    margin-top: 2.4rem;
  }
}
