.header__languagepicker {
  .dropdown__control {
    border: 0;
    height: auto;
    min-height: 0;
    cursor: pointer;
    flex-wrap: nowrap;

    .dropdown__value-container {
      padding: 0;
      cursor: pointer;
    }
  }

  .dropdown__single-value {
    max-width: unset;
    position: static;
    transform: none;
  }

  .dropdown__indicators .dropdown__indicator {
    color: var(--primary-text);
  }
}
