@keyframes alert {
  0% {
    border-color: var(--shadow);
  }
  10% {
    border-color: var(--error);
  }
  20% {
    border-color: var(--shadow);
  }
  30% {
    border-color: var(--error);
  }
  40% {
    border-color: var(--shadow);
  }
  50% {
    border-color: var(--error);
  }
  60% {
    border-color: var(--shadow);
  }
  70% {
    border-color: var(--error);
  }
  80% {
    border-color: var(--shadow);
  }
  90% {
    border-color: var(--error);
  }
  100% {
    border-color: var(--shadow);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-1.9rem);
  }
  60% {
    transform: translateY(-1rem);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes fade-in-down {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes horizontal-shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes slide-in-left {
  from {
    transform: translateX(-6.25rem);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes scale {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes spinner-solid-circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
