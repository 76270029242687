.checkbox-wrapper {
  input {
    margin: 0;
    padding: 0;
    display: grid;
    place-items: center;
    appearance: none;
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 1.6rem;

    &[disabled] {
      opacity: var(--disabled-opacity);
    }
  }

  > label {
    display: grid;
    grid-template-columns: auto 1fr;

    color: var(--primary-text);
    cursor: pointer;
    line-height: 1.2;

    > span {
      min-width: 0;
    }

    &.is-error {
      color: var(--error);
    }
  }
}

.checkbox-wrapper:not(.checkbox-toggle) {
  input[type='checkbox'] {
    border-radius: 0.2rem;
    border: 1px solid var(--white);

    &::after {
      content: '';
      width: 0.6rem;
      height: 1.2rem;
      margin-top: -0.2rem;
      border-width: 0 0.2rem 0.2rem 0;
      transform: rotate(45deg);
      border: solid transparent;
    }

    &:checked {
      background-color: var(--white);

      &::after {
        content: '';
        width: 0.6rem;
        height: 1.2rem;
        margin-top: -0.2rem;
        border: solid var(--background);
        border-width: 0 0.2rem 0.2rem 0;
        transform: rotate(45deg);
      }
    }
  }

  input[type='radio'] {
    border: 0.8rem solid var(--white);
    border-radius: 50%;
    transition: border-width 0.2s ease-out;
    will-change: border-width;
    border-width: 0.1rem;
    align-self: center;

    &:checked {
      border-width: 0.55rem;
    }
  }
}

.checkbox-wrapper.checkbox-toggle {
  --track-width: 2.4rem;
  --track-height: 1.2rem;

  input {
    width: var(--track-width);
    height: var(--track-height);
    border-radius: var(--track-height);
    background-color: transparent;
    border: 1px solid var(--primary-text);
    will-change: background-color;
    margin-top: 0.24rem;

    &:checked {
      background-color: var(--white);

      &::after {
        border: none;
        transform: translateX(calc(var(--track-width) - 100% - 3px));
        background-color: var(--background);
      }
    }

    &::after {
      content: '';
      border-radius: 50%;
      width: calc(var(--track-height) - 4px);
      height: calc(var(--track-height) - 4px);
      transform: translateX(1px);
      background-color: var(--primary-text);
      justify-self: flex-start;
      transition: 0.1s ease-out;
      will-change: background-color, transform;
    }
  }
}
