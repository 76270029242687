@import '../../_styles/breakpoints';

.account {
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr;

  @include breakpoint-up(mobile) {
    grid-template-columns: 1fr 1fr;
  }

  > h1 {
    @include breakpoint-up(mobile) {
      grid-column: span 2;
      margin-bottom: 3rem;
    }
    text-align: center;
  }

  p {
    font-size: 1.4rem;
  }

  .button {
    margin-top: 1.6rem;
    width: 100%;
    justify-content: center;
  }
}

.account__sign-in {
  @include breakpoint-up(mobile) {
    border-right: 0.15rem solid #f5f5f5;
    padding-right: 3.5rem;
  }
}
.account__sign-in-summary {
  @include breakpoint-up(mobile) {
    margin-bottom: 2.3rem;
  }
}

@include breakpoint-up(mobile) {
  .account__guest {
    grid-column-start: 2;
  }
}
