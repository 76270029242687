.dropdown {
  .dropdown__control {
    background-color: transparent;
    border: 1px solid var(--dark-labels);
    border-radius: var(--border-radius);
    height: var(--input-height);
    min-height: var(--input-height);
    box-shadow: none;

    &.dropdown__control--is-focused,
    &.dropdown__control--menu-is-open {
      border-color: var(--primary-text);
    }

    &.dropdown__control--menu-is-open {
      .dropdown__indicator {
        color: var(--primary-text);
      }
    }

    .dropdown__value-container {
      padding: 0.2rem 1.4rem;
      cursor: text;

      .dropdown__placeholder {
        color: var(--primary-text);
      }

      .dropdown__single-value {
        color: var(--primary-text);
      }

      .dropdown__multi-value {
        background-color: var(--secondary-text);
        border-radius: var(--border-radius);

        .dropdown__multi-value__label {
          color: var(--primary-text);
          font-size: var(--font-size);
        }
        .dropdown__multi-value__remove {
          color: var(--primary-text_dark);
          &:hover {
            color: var(--error);
            background-color: inherit;
            cursor: pointer;
          }
          svg {
            height: 1.8rem;
            width: 1.8rem;
          }
        }
      }
    }

    .dropdown__indicator-separator {
      display: none;
    }
    .dropdown__indicator {
      color: var(--secondary-text);
    }
  }

  .dropdown__menu {
    &-list {
      padding: 0;
    }

    background-color: var(--white);
    border-radius: var(--border-radius);
    z-index: 100;
    overflow: hidden;

    .dropdown__option {
      padding: 0.8rem 1.7rem;

      @media (hover: none) and (pointer: coarse) {
        padding: 2rem 1.7rem;
      }

      color: var(--accent);

      &.dropdown__option--is-focused {
        cursor: pointer;
      }

      &.dropdown__option--is-selected {
        background-color: var(--accent);
        color: var(--primary-text);
      }
    }
    .dropdown__no-options-message {
      color: var(--primary-text_dark);
      text-align: left;
      padding: 0.8rem 1.7rem;
    }
  }

  &.dropdown--is-disabled {
    pointer-events: none;
    opacity: var(--disabled-opacity);
    background-color: var(--input-background);
  }

  &.contains-empty-option {
    .dropdown__option:first-child {
      color: var(--primary-text_dark);
    }
  }

  &.error {
    .dropdown__control {
      color: var(--error);
      border-color: var(--error);
      background-color: var(--input-background);

      .dropdown__value-container {
        .dropdown__placeholder {
          color: var(--error);
        }
      }

      &.dropdown__control--is-focused,
      &.dropdown__control--menu-is-open {
        border-color: var(--error);
      }

      &.dropdown__control--menu-is-open {
        .dropdown__indicator {
          color: var(--error);
        }
      }
    }

    .dropdown__indicator {
      color: var(--error);
    }
  }
}
