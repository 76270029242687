.input {
  width: 100%;
  outline: none;
  text-align: left;
  padding: 1.3rem 1.4rem;
  background: var(--input-background);
  border: 1px solid var(--dark-labels);
  color: var(--primary-text);
  border-radius: var(--border-radius);
  font-size: var(--font-size);
  box-sizing: border-box;
  height: var(--input-height);

  &::placeholder {
    color: var(--secondary-text);
  }

  &:disabled {
    opacity: var(--disabled-opacity);
    background-color: var(--input-background);
  }

  &.error {
    color: var(--error);
    border-color: var(--error);
    background-color: var(--input-background);

    &::placeholder {
      color: var(--error);
    }

    &:focus {
      border-color: var(--error);
    }
  }

  &:focus {
    border-color: var(--primary-text);
  }

  &::placeholder {
    color: var(--secondary-text);
  }
}

.icon-start {
  position: absolute;
  left: 1.5rem;
  top: 3.8rem;

  & + input {
    padding-left: 4.6rem;
  }
}

.icon-end {
  position: absolute;
  right: 1.5rem;
  top: 3.8rem;

  &.disabled-icon {
    top: 3.9rem;
  }
}
