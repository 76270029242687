@import '../../../_styles/breakpoints.scss';

.voucher-table {
  @include breakpoint-up('tablet') {
    min-width: auto;
  }
  width: 100%;

  thead {
    tr {
      background-color: var(--table);
      th {
        border: none;
        @include breakpoint-down('mobile') {
          &:last-child {
            > span {
              text-align: right;
            }
          }
        }
      }
    }
  }

  .voucher-table-row {
    background-color: var(--table-50);
    > span {
      padding: 1.1rem 1.2rem 1.3rem;
    }
  }
}
.status-indicator {
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  margin-right: 0.8rem;
}
