@import '../../_styles/breakpoints';

.multistep {
  --icon-size: 3.2rem;
  @include breakpoint-up(tablet) {
    --icon-size: 2.4rem;
  }

  margin: auto;
  margin-top: 2.4rem;
  max-width: 520px;
  display: flex;
  justify-content: stretch;

  li {
    width: 100%;

    &:first-child .multistep__number-wrapper::before,
    &:last-child .multistep__number-wrapper::after {
      height: 0;
    }

    &:not(.multistep__current) {
      @include breakpoint-down(tablet) {
        display: none;
      }
    }
  }

  [aria-current] {
    @include breakpoint-down(tablet) {
      display: flex;
      align-items: center;
    }
  }

  &__number {
    display: block;
    text-align: center;
    border-radius: 50%;
    font-weight: 700;
    line-height: var(--icon-size);
    margin: auto 1.6rem;
    flex: 0 0 var(--icon-size);
    height: var(--icon-size);
    aspect-ratio: 1;
    transition: 0.5s ease-out;
    border: 1px solid var(--white);

    @include breakpoint-up(tablet) {
      font-size: 1.4rem;
      background-color: var(--dark-labels);
      border: none;
    }

    [aria-current] &,
    &--current {
      @include breakpoint-up(tablet) {
        color: var(--background);
        background-color: var(--dark-cta);
      }
    }

    &--complete {
      opacity: 0;
      transform: perspective(500px) scale3d(1.7, 1.7, 1.7);

      & + .multistep__complete {
        opacity: 1;
      }
    }
  }

  &__number-wrapper {
    @include breakpoint-up(tablet) {
      display: flex;
      width: 100%;
      position: relative;

      &::before,
      &::after {
        content: '';
        height: 1px;
        flex-grow: 1;
        background: var(--dark-labels);
        width: 100%;
        display: block;
        margin-top: calc(var(--icon-size) / 2);
      }
    }
  }

  &__current {
    @include breakpoint-down(tablet) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__length {
    font-weight: 400;
    font-size: 1.2rem;
    @include breakpoint-up(tablet) {
      display: none;
    }
  }

  &__label {
    @include breakpoint-up(tablet) {
      text-align: center;
      font-size: 1.4rem;
      font-weight: 600;
      display: block;
      margin-top: 0.4rem;
      white-space: nowrap;
    }
  }

  &__complete {
    display: block;
    margin: auto 0.8rem;
    color: var(--dark-cta);
    width: 2.4rem;
    height: 2.4rem;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    transition: 0.5s ease-out;
    opacity: 0;
  }
}
