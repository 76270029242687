.tabs__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  .buttons__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    //Very small screens
    @media only screen and (max-width: 550px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .button {
        text-align: center;
        margin-bottom: 1rem;
      }
    }

    .tab__button {
      font-size: 2rem;
      font-weight: 800;
      opacity: 60%;
      padding: 1.6rem;
      background-color: transparent;
      align-items: center;
      border: 2px solid var(--grey-400);
      width: 50%;
      @media only screen and (max-width: 550px) {
        width: 100%;
      }

      &:hover {
        opacity: 1;
      }

      &.selected {
        opacity: 100%;
        font-weight: 800;
        border: 2px solid var(--grey-200);
      }

      &:not(:last-child) {
        margin-right: 4rem;

        @media only screen and (max-width: 550px) {
          margin-right: 0;
        }
      }
    }
  }
  .tab__content {
    width: 100%;
    transition: all ease-in-out 5ms;
  }
}
