@mixin divider {
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    var(--white-20a) 33%,
    var(--white-20a) 66%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 90% 1px;
  background-repeat: no-repeat;
  background-position: top center;
}
