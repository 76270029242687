@import '../../../_styles/breakpoints';

.store-link {
  user-select: none;
}

.dropdown-item-content > .icon {
  margin-right: 1rem;
}

.dropdown-item-content {
  display: flex;
  align-items: center;
}

.global-header {
  position: relative;
  z-index: 10;

  &.b2b-header {
    background-color: var(--header-gradient-start);

    .global-header__links {
      background-color: var(--background);

      &__shops a {
        &[aria-current] {
          background-color: var(--header-gradient-start);
        }

        &:hover {
          background-color: var(--header-gradient-start);
        }
      }
    }
  }

  &.b2c-header {
    background-color: var(--background);

    .global-header__links {
      background-color: var(--header-gradient-start);

      &__shops a {
        &[aria-current] {
          background-color: var(--background);
        }

        &:hover {
          background-color: var(--background);
        }
      }
    }
  }

  &__links {
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 0.2rem;
    }

    ul {
      display: flex;
    }

    &__shops {
      align-self: flex-end;

      li + li {
        margin-left: 0.8rem;
      }

      a {
        display: inline-block;
        font-size: 1.4rem;
        padding: 0.4rem 1rem 0.3rem 1rem;
        border-top-left-radius: 0.4rem;
        border-top-right-radius: 0.4rem;
      }
    }

    &__external-links {
      display: flex;
      align-items: center;
      line-height: 1;

      .button {
        font-size: 1.6rem;
      }

      > * + *,
      li + li {
        margin-left: 2.4rem;
      }
    }
  }

  &__content {
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 1.8rem;
      padding-bottom: 1.8rem;

      img {
        max-height: 24px;
        margin-left: 1.6rem;

        @include breakpoint-up(tablet) {
          margin-left: 0;
          max-height: 32px;
        }
      }
    }

    &__navigation {
      display: flex;
      align-items: center;

      img {
        margin-right: 2rem;
      }
    }

    &__actions {
      display: flex;

      .button {
        font-size: 1.6rem;
      }

      > * + * {
        margin-left: 0.8rem;
      }
    }

    &__auth {
      display: flex;
      align-items: center;
      user-select: none;

      .icon {
        margin-right: 0.6rem;
      }
      &__admin {
        opacity: 80%;
        font-size: 1.2rem;

        .tooltip__trigger {
          margin-left: 0.6rem;
        }
      }
      &__info {
        display: flex;
        flex-direction: column;
        margin-right: 2rem;

        span {
          line-height: 1.5;

          &:last-of-type {
            font-weight: 700;
          }
        }
      }
    }

    .tippy-content {
      ul {
        > span {
          display: block;
          border-bottom: 1px solid var(--grey-200);
          margin: 1rem 1.5rem 0 1.5rem;
          p {
            color: #6a778b;
            line-height: 1.8rem;
            text-align: center;
            padding-bottom: 1rem;
            font-size: 1.1rem;
            padding: 0;
          }
          .company-name {
            padding-top: 0.2rem;
            padding-bottom: 0.4rem;
            font-weight: bold;
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}
