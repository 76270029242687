.segmented-control {
  display: flex;
  align-items: center;
  font-size: var(--font-size);

  &.large {
    font-size: 1.8rem;
  }

  button.plain {
    display: block;
    color: var(--primary-text);

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 0.2rem;
      background-color: transparent;
      margin-top: 0.3rem;
    }

    &.active {
      font-weight: 700;
      color: var(--primary);
      &:after {
        background-color: var(--primary);
      }
    }
  }

  button + button {
    margin-left: 3.5rem;
  }
}
