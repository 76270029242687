:root {
  --font-size: 1.4rem;
  --font-family: proxima-nova, sans-serif;
  --border-radius: 0.8rem;
  --disabled-opacity: 0.75;
  --navbar-height: 6.5rem;
  --input-height: 4.8rem;
  --content-width: 720px;

  --input-icon-height: 2.2rem;
  --input-label-bottom-margin: 0.5rem;

  --scrollbar-thumb-color: #ccc;
  --scrollbar-track-color: #333;
  --scrollbar-hover-thumb-color: var(--white);
}
