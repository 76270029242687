@import '../../_styles/breakpoints';

@include breakpoint-down('tablet') {
  .table tbody tr {
    border: 0;
  }
}

.order-detail {
  .detail-header {
    margin-bottom: 0.5rem;
  }

  .products-actions {
    margin-top: 0.5rem;
  }

  .products-actions > .button {
    margin-bottom: 1rem;
    margin-top: 1rem;
    justify-content: center;
    @include breakpoint-down('mobile') {
      max-width: 100%;
    }
  }

  .button--with-icon.button--with-text {
    padding-left: 0;
  }

  .track-and-trace-wrapper {
    td {
      vertical-align: middle;
    }
    .name {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }
  .track-and-trace {
    font-weight: 400;
    border: none;
    background: transparent;
    text-align: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--white-90a);
    text-decoration: underline;

    &__icon {
      margin-right: 1rem;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        text-decoration: none;
      }
    }
  }

  .payment-method {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__icon {
      height: 1.8rem;
      border-radius: 0.5rem;
      margin-left: 1rem;
      margin-right: 1rem;
    }
    &__pay-by-link {
      background-color: var(--white);
      height: 1.8rem;
      border-radius: 0.5rem;
      margin-left: 1rem;
      margin-right: 1rem;
      > svg {
        margin: 0 0.5rem;
        filter: invert(1);
      }
    }
  }
}

@include breakpoint-up('mobile') {
  .pagination-and-download-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    > .pagination-wrapper {
      align-items: center;
      margin-right: 2rem;
      width: 100%;
    }
  }
}

.download-invoice {
  .download-button {
    margin-top: 1rem;
    margin-right: 1rem;
    height: 3.5rem;
  }
}

.download-vouchers {
  height: 3.5rem;
  @include breakpoint-down('mobile') {
    min-width: 100%;
  }
}

.summary-table {
  .status-icon {
    margin-right: 0.8rem;
  }
}

.pagination {
  margin-top: 1rem;
}
