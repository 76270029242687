:root {
  --background: #1b243d;
  --header-gradient-start: #05435f;
  --footer: #1a1a1a;

  --primary: #df1f12;
  --primary-dark: #bb1a0f;

  --secondary: #333b51;
  --secondary-dark: #242939;

  --accent: #3782a3;

  --error: #df1f12;
  --error-dark: #f43f5e;

  --primary-text: #fff;
  --primary-text-dark: #d9d9d9;

  --secondary-text: #6f7784;

  --warning: #3f484f;

  // Non dynamic colors (Remove if possible)
  --in-progress: #f59e0b;
  --success: #10b981;
  --white: #ffffff;
  --white-10a: rgba(255, 255, 255, 0.1);
  --white-20a: rgba(255, 255, 255, 0.2);
  --white-30a: rgba(255, 255, 255, 0.3);
  --white-90a: rgba(255, 255, 255, 0.9);
  --background-transparent: rgba(37, 37, 37, 0.7);
  --input-background: transparent;
  --shadow: rgba(34, 36, 38, 0.15);
  --safari-transparent: rgba(255, 255, 255, 0);
  --modal-dimmer: rgb(0, 0, 0, 0.85);
  --black-transparent: rgba(0, 0, 0, 0.2);

  --dark-cta: #10b981;
  --dark-labels: #3d4e66;
  --grey-100: #f8fafc;
  --grey-200: #ced6e0;
  --grey-300: #b3bdcc;
  --grey-400: #9aa6b8;
  --grey-700: #53637a;
  --background-highlight: #253374;
  --cross-sell: #002548;
  --customer-portal-navigation: #232c38;

  --table: #2a3a52;
  --table-50: rgba(42, 58, 82, 0.5);
}
