@import '../../_styles/breakpoints';

.discount-form {
  form {
    display: flex;
    align-items: flex-end;

    .button {
      padding-top: 1.6rem;
      padding-bottom: 1.6rem;
    }
  }

  .button--plain-link .button__label {
    text-decoration: underline;
  }

  @include breakpoint-down(mobile) {
    .input-wrapper {
      width: 100%;
    }
  }
}
