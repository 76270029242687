@import '../../../../_styles/breakpoints';

.product--detail__information {
  ul {
    padding: 0 2.2rem;
    @include breakpoint-down(mobile) {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 0.8rem 2.4rem;
    }
  }

  li {
    color: var(--primary-text);
    background-color: transparent;
    border: 0;
    appearance: none;
    position: relative;
    font-weight: 600;

    > div {
      display: grid;
      @include breakpoint-up(tablet) {
        display: inline-grid;
      }
      grid-template-columns: min-content 1fr min-content;
      align-items: flex-start;

      > .icon {
        margin-top: 0.5rem;
        margin-right: 0.8rem;
      }
    }

    .icon {
      margin-top: 0.5rem;
    }
  }
}

.product__information {
  &__tooltip {
    appearance: none;
    background: none;
    border: 0;
    vertical-align: middle;
    color: var(--grey-300);
  }

  &__disclaimer {
    margin-top: 2.4rem;
    opacity: 0.5;
    font-size: 1.3rem;
    line-height: 1.3;
  }
}
