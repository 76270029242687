@import '../../_styles/breakpoints';

.add-to-cart-form {
  position: fixed;
  bottom: 1.6rem;
  z-index: 3;
  padding: 1.6rem;
  background-color: var(--white-90a);
  color: var(--header-gradient-start);
  border-radius: var(--border-radius);
  height: fit-content;

  @supports (backdrop-filter: blur(5px)) {
    backdrop-filter: blur(5px);
  }

  @include breakpoint-down(mobile) {
    left: 0;
    right: 0;
    margin: auto;
    width: calc(100% - 1.6rem);
  }

  h1,
  .product__order-information {
    display: none;
  }

  form {
    grid-template-areas:
      'quantity quantity'
      'disclaimer button';
    grid-template-columns: auto min-content;

    @include breakpoint-up(mobile) {
      display: flex;
      gap: 0;
    }

    fieldset {
      gap: 0;
      @include breakpoint-down(mobile) {
        gap: 0.8rem; /* Should only be for B2B probably */
      }
    }

    fieldset,
    legend + * {
      margin-top: 0;
    }

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      grid-area: quantity;
    }
  }

  &--B2C {
    form {
      display: flex;
      justify-content: space-around;
      gap: 0;
    }

    .add-to-cart-form__price {
      margin-right: 3rem;
    }
  }

  &--B2B {
    .add-to-cart-form__price {
      display: flex;

      @include breakpoint-up(mobile) {
        justify-content: space-between;
      }

      @include breakpoint-down(mobile) {
        gap: 0.8rem;

        &::after {
          content: '\00a0\00d7\00a0';
        }
      }

      &__personalization-text {
        margin-top: 3.2rem;

        input {
          color: var(--secondary-text);
          background-color: var(--white);
        }

        &.--mobile {
          label {
            color: var(--secondary-text);
          }
        }
      }
    }
  }

  &--B2B {
    form > div {
      flex-direction: row;
      justify-content: stretch;

      @include breakpoint-down(mobile) {
        gap: 0.8rem;
      }

      @include breakpoint-up(mobile) {
        flex-direction: column;
        align-items: stretch;
      }
    }

    fieldset > * {
      grid-column: span 4;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .quantity-input {
      @include breakpoint-up(mobile) {
        margin-top: 1.6rem;
      }
    }

    fieldset {
      @include breakpoint-down(mobile) {
        width: 100%;
      }
    }

    .quantity-input__quantity {
      flex: 1 1 100%;
    }
  }

  .button {
    margin-left: 1rem;
    justify-content: center;
    @include breakpoint-down(mobile) {
      grid-area: button;
      align-self: center;
      width: 100%;
      max-width: 70px;
      margin-left: 0;
    }
  }

  &__business-disclaimer {
    font-size: 1.2rem;
    line-height: 1.4;
    margin-bottom: 1.6rem;
    grid-area: disclaimer;
    color: var(--grey-400);

    &.--mobile {
      text-align: center;
      margin: 0.5rem auto 0 auto;
      display: block;
    }
  }

  &__add-to-cart-disclaimer {
    font-size: 1.2rem;
    line-height: 1.4;
    margin-bottom: 1.6rem;
    margin-top: 0.8rem;
    grid-area: disclaimer;
    color: var(--grey-400);

    &.--mobile {
      text-align: center;
      margin: 0.5rem auto 0 auto;
      display: block;
    }
  }

  @include breakpoint-up(mobile) {
    position: static;
    padding: 2.4rem;
    transform: none;
    background-color: var(--header-gradient-start);
    color: var(--primary-text);

    h1,
    .product__order-information {
      display: block;
    }

    .product__order-information {
      margin: 0 0 2rem;
      font-size: 1.3rem;
      color: var(--grey-400);
    }

    form {
      flex-direction: column;

      > div {
        margin-bottom: 1.6rem;
      }
    }

    .button {
      margin-left: 0;
    }
  }
}
