@import '../../_styles/mixins';
@import '../../_styles/breakpoints';

#dropin-container {
  max-width: 500px;
  margin: auto;
  position: relative;
  min-height: 100px;

  .spinner-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    display: none;
  }

  .spinner-wrapper:first-child:last-child {
    display: block;
  }
}

.adyen-checkout {
  &__payment-method {
    background-color: var(--background);
    border: none;
    margin: 0;
    padding-bottom: 0.8rem;

    &__header {
      color: var(--primary-text);
      font-weight: 600;
    }

    &__name {
      color: var(--primary-text);
    }

    &__name--selected {
      font-weight: 600;
    }

    &__radio {
      background-color: var(--input-background);
      border: 1px solid var(--primary-text);

      &--selected {
        border-width: 6px;
      }

      &:after {
        content: none;
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      transform: translateX(5%);
      @include divider();
    }
  }

  &__label {
    &__text {
      font-size: 1.4rem;
      color: var(--primary-text);

      &--error {
        color: var(--error);
      }
    }

    &--focused {
      color: var(--primary-text);

      .adyen-checkout__label__text {
        color: var(--primary-text);
      }
    }
  }

  &__input {
    background-color: var(--input-background);
    border-color: var(--secondary-text);
    border-radius: var(--border-radius);
    color: var(--primary-text);

    &--error {
      color: var(--error);
      border-color: var(--error);
      background-color: var(--input-background);
    }

    &--focus,
    &--focus:hover {
      border-color: var(--primary-text);
      box-shadow: none;
    }
  }

  &__dropdown__element {
    color: var(--secondary);
    font-weight: 600;
    font-size: 1.6rem;
  }

  &__spinner {
    border-color: var(--primary);
    border-top-color: transparent;
  }

  &__button.adyen-checkout__button {
    background-color: var(--primary);

    &--loading {
      background-color: var(--primary-dark);
    }

    &:hover,
    &:focus:not(.focus-visible) {
      background-color: var(--primary-dark);
    }
  }

  &__error-text {
    color: var(--error);
    font-weight: 300;
    font-size: 1.4rem;
  }
}

.payments {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 5rem;

  @include breakpoint-up(tablet) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  h1 {
    font-weight: 400;
  }

  &__direct {
    display: flex;
    flex-direction: column;
    margin-right: 5rem;
    &__title {
      margin-left: 1rem;
    }
  }
}

[class*='DropinComponent-module_adyen-checkout__payment-method__image__wrapper'] {
  margin-right: 1.2rem;
}
[class*='DropinComponent-module_adyen-checkout__payment-method__image'] {
  height: 22px;
  width: 32px;
}
