@import '../../_styles/mixins';
@import '../../_styles/breakpoints';
@import '../../_styles/colors';

.pick-company-loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @include breakpoint-down(tablet) {
    min-height: 75vh;
  }
}

.pick-company {
  display: flex;
  flex-direction: column;
  align-items: center;

  .pick-company-list {
    max-height: 35vh;
    overflow: auto;
    margin-top: 1.6rem;
    padding-right: 0.3rem;

    .pick-company-list-item {
      padding: 1rem;
      background-color: var(--table);
      border-radius: 0.5rem;

      &__admin {
        opacity: 80%;
        margin-left: 1rem;
      }
    }
  }

  &__content {
    max-width: 40rem;

    * {
      width: 100%;
    }

    p:first-of-type {
      margin-top: 2.4rem;
    }

    hr {
      @include divider;
      margin-top: 3rem;
    }

    &__new-company {
      margin-top: 1.4rem;
    }

    a {
      text-align: center;
    }

    button,
    a {
      margin-top: 1.4rem;
    }
  }
}
