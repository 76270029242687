.input-date-wrapper {
  position: relative;
  width: fit-content;

  .ui.input > input {
    padding-right: 4rem;
  }

  .input {
    width: 15rem;
  }

  button.datepicker-icon {
    position: absolute;
    right: 1rem;
    height: var(--input-height);
    top: 0;
    background: transparent;
    border: 0;

    & > * {
      color: var(--white-90a);
    }
  }

  &.has-label {
    .datepicker-icon {
      top: calc(#{var(--input-icon-height)} + #{var(--input-label-bottom-margin)});
    }
  }
}
