@import '../../../_styles/breakpoints';

.modal__box {
  @include breakpoint-up(mobile) {
    min-height: 90vh;
  }
}

.modal__content {
  overflow: auto;
  h2 {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    text-align: center;

    .title-icon {
      margin-right: 1rem;
    }
  }

  .google-places-container {
    margin-top: 2rem;
  }
}

.no-addresses {
  width: 100%;
  font-weight: bold;
  opacity: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pickup-point-name {
  margin-bottom: 1rem;
}
.half-size {
  width: 50%;
}

.shipping-list {
  overflow: auto;

  &__item {
    border: 1px solid var(--white-10a);
    border-radius: 0.8rem;
    padding: 1.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 0.5rem;

    &:not(:first-child) {
      margin-top: 1.6rem;
    }

    &__default {
      margin-top: 0.8rem;
      font-weight: 600;
      color: var(--accent);
    }
  }

  &__B2C {
    max-height: 23rem;
  }

  @include breakpoint-up(mobile) {
    br {
      content: '';
      display: inline;
      &::before {
        content: ', ';
      }
    }
  }

  @include breakpoint-up(mobile) {
    address {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 0;
    }
  }
}

.shipping-list-add {
  display: block;
  margin: 1.6rem auto 0;
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
  @include breakpoint-down(mobile) {
    width: 100%;
  }
}

.edit-and-delete {
  display: flex;
  align-content: 'center';
}
