@import '../../_styles/breakpoints';

.order-details__form {
  display: block;
  margin: 4rem 0 2rem;

  fieldset {
    @include breakpoint-up(tablet) {
      max-width: 290px;
    }
    margin: auto;
  }

  .actions {
    margin-top: 5.6rem;
  }
}
