@import '../../../_styles/breakpoints.scss';

.order-detail-item {
  margin-top: 2rem;
  border-radius: 0.5rem;

  &__header {
    margin-bottom: 0.5rem;
  }

  @include breakpoint-down(tablet) {
    padding: 1.6rem;
    background-color: var(--table);
    position: relative;

    .order-detail-item-toggle {
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-left: 0;

      h2 {
        text-transform: uppercase;
        font-weight: 700;
      }

      .icon {
        transition: transform 250ms ease-out;
      }
    }

    &--open {
      .button .icon {
        transform: rotate(-180deg);
      }

      h2 {
        margin-bottom: 0.8rem;
      }

      .icon {
        margin-bottom: 0.8rem;
      }
    }

    &--closed {
      dl,
      div {
        display: none;
      }
    }
  }
}
