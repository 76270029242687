@import '../../../_styles/breakpoints';

.quantity-input {
  &--buttons {
    display: flex;
    align-items: center;
  }

  &--dark {
    input {
      color: var(--primary-text);
    }
  }

  &--cart,
  &--light {
    input {
      color: var(--secondary);
    }

    .quantity-input__btn {
      background-color: var(--accent);

      path {
        fill: var(--primary-text);
      }
    }
  }

  &__btn {
    cursor: pointer;
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 0.4rem;
    transition: 100ms ease-out background-color, 100ms ease-out transform;

    &:hover,
    &:focus:not(.focus-visible) {
      background-color: var(--secondary-text);

      .icon {
        transform: scale(1.1);
      }
    }

    &:active {
      background-color: var(--grey-400);
      .icon {
        transform: scale(0.9);
      }
    }
  }

  input {
    width: 4.2rem;
    background-color: transparent;
    border: none;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.5;
    text-align: center;
    height: 100%;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  &--no-buttons {
    @include breakpoint-down(mobile) {
      display: flex;
    }

    input {
      background-color: var(--white);
      color: var(--background);
      border-radius: 8px;
      width: 9rem;
      height: 4rem;
      text-align: right;
      padding: 1.6rem;
      font-weight: 400;
      @include breakpoint-down(mobile) {
        font-weight: 700;
        width: 100%;
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  &__output {
    @include breakpoint-down(mobile) {
      min-width: 100px;

      &::before {
        content: '\00a0=\00a0';
      }
    }

    @include breakpoint-up(mobile) {
      margin-top: 1.6rem;
      padding-top: 1.6rem;
      border-top: 1px dotted var(--white);
      display: block;
    }
  }
}
