@import '../../../_styles/breakpoints';

.input--phone {
  --prefix-width: 8.8rem;
  > div {
    display: flex;
  }
  &__wrapper {
    display: flex;
    width: 100%;
  }

  &__prefix {
    width: var(--prefix-width);
    min-width: 9rem;
  }

  &__number {
    margin-left: 1rem;
    width: 100%;
  }
}

.half-width {
  max-width: 40rem;
}
