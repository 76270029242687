.input-wrapper {
  position: relative;
  min-width: 0;

  .error-message {
    padding-top: 0.8rem;
  }

  &.error input {
    color: var(--error);
    border-color: var(--error);
    background-color: var(--input-background);

    &::placeholder {
      color: var(--error);
    }

    &:focus {
      border-color: var(--error);
    }
  }
}
.input-wrapper-label {
  &__optional {
    margin-left: 0.5rem;
  }
}

.input-wrapper-label:not(:last-child) {
  display: flex;
  align-items: center;
  min-height: var(--input-icon-height);
  margin-bottom: var(--input-label-bottom-margin);
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.4;
  color: var(--primary-text);

  > :not(:first-child) {
    margin-left: 0.75rem;
  }

  label i,
  label svg {
    width: var(--input-icon-height);
    height: var(--input-icon-height);
  }
}

.ui.input,
.ui.selection.dropdown {
  min-width: 100%;
}
